import * as React from 'react';
import { msTypes } from '../../../../utils/definitions';
import EventStepper from '../../../../../../components/EventStepper';
import { formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../../components/InputHandler';
import FormSection from '../../../../../../components/FormSection';
import { styled } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import colors from '../../../../../../config/theme/colors';
import { isNil } from 'Utility/ramdaReplacement';
import ConfirmationDialog from '../../../../../../components/ConfirmationDialog';
import { StepperHeaderValuePair } from '../../../../../../components/EventStepper/components';

const ClearIconStyled = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

const TitleRow = ({
  name,
  index,
  handleDelete,
}: {
  name: string;
  index: number;
  handleDelete: (name: string, index: number) => void;
}): JSX.Element => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
    <div style={{ display: 'inline-flex', minWidth: '37.8%', marginRight: '1rem' }}>
      {fm(`diagnosis.msType.${name}`)}
    </div>
    <div style={{ display: 'inline-flex' }}>
      <ClearIconStyled onClick={(): void => handleDelete(name, index)} />
    </div>
  </div>
);

const MsType = ({ editing, formData, newDiagnosis }: IOwnProps): JSX.Element => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [deleteDialogData, setDeleteDialogData] = React.useState<{ name: string; index: number } | undefined>(
    undefined,
  );

  const onDelete = (name: string, index: number): void => {
    const newTypeOfDisease = JSON.parse(JSON.stringify(formData.document.typeOfDisease));
    if (Array.isArray(newTypeOfDisease) && newTypeOfDisease.length > 0 && !isNil(newTypeOfDisease[index][name])) {
      newTypeOfDisease[index][name] = null;
    }
    formData.onChange && formData.onChange({ typeOfDisease: newTypeOfDisease });
  };

  const openDeleteDialog = (name: string, index: number): void => {
    setDeleteDialogOpen(true);
    setDeleteDialogData({ name, index });
  };
  const deleteCancelCallback = (): void => {
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };
  const deleteConfirmCallback = (): void => {
    deleteDialogData && onDelete(deleteDialogData.name, deleteDialogData.index);
    setDeleteDialogOpen(false);
    setDeleteDialogData(undefined);
  };

  return (
    <React.Fragment>
      <FormSection header="diagnosis.msType.title">
        {editing ? (
          <EventStepper
            name="typeOfDisease"
            formData={formData}
            stepLabelText={(d: IMSType): JSX.Element => <span>{d?.date ? formatPartialDate(d.date) : ''}</span>}
            stepContent={(d: IMSType): JSX.Element => {
              if (!isNil(d.dit) || !isNil(d.dis)) {
                return (
                  <React.Fragment>
                    <StepperHeaderValuePair
                      key={`${d.type}`}
                      header={fm('diagnosis.msType.title')}
                      value={d.type ? fm(`diagnosis.msType.name.${d.type}`) : '-'}
                    />
                    {!isNil(d.dit) && (
                      <StepperHeaderValuePair
                        key={`${d.dit}`}
                        header={fm('diagnosis.msType.dit')}
                        value={fm(`diagnosis.opts.yesNoUnknown.${d.dit}`)}
                      />
                    )}
                    {!isNil(d.dis) && (
                      <StepperHeaderValuePair
                        key={`${d.dis}`}
                        header={fm('diagnosis.msType.dis')}
                        value={fm(`diagnosis.opts.yesNoUnknown.${d.dis}`)}
                      />
                    )}
                  </React.Fragment>
                );
              }
              return <div>{d.type ? fm(`diagnosis.msType.name.${d.type}`) : ''}</div>;
            }}
            addNewTextHeader=""
            addNewTextButton="general.new"
            previousEventsTextHeader=""
            buttonDisabled={
              formData.document.typeOfDisease?.length === 1 && !formData.document.typeOfDisease?.[0].type ? true : false
            }
            buttonDisabledMessage={fm(`diagnosis.msType.newDisabledMessage`)}
            editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
              <React.Fragment>
                <div style={{ marginBottom: '2rem' }}>
                  {fm('general.date')}
                  <InputHandler
                    type="PartialDate"
                    name="date"
                    dateDefault="now"
                    formData={{
                      onChange: onChange,
                      document: {
                        date: formData.document?.typeOfDisease?.[index]?.date,
                      },
                    }}
                    editing={!!editing}
                  />
                </div>
                <div style={{ marginBottom: '2rem' }}>
                  {fm('diagnosis.msType.title')}
                  <InputHandler
                    type="Select"
                    name="type"
                    formData={{
                      onChange: onChange,
                      document: {
                        type: formData.document?.typeOfDisease?.[index]?.type,
                      },
                    }}
                    options={msTypes}
                    optionFormatter={(s: string | number): JSX.Element => fm(`diagnosis.msType.opts.${s}`)}
                    editing={!!editing}
                    placeholder={'diagnosis.msType.placeholder'}
                  />
                </div>
                {!isNil(formData.document?.typeOfDisease?.[index]?.dit) && (
                  <div style={{ marginBottom: '2rem' }}>
                    <TitleRow name="dit" index={index} handleDelete={openDeleteDialog} />
                    <InputHandler
                      type="Radio"
                      name="dit"
                      formData={{
                        onChange: onChange,
                        document: {
                          dit: formData.document?.typeOfDisease?.[index]?.dit,
                        },
                      }}
                      options={['yes', 'no', 'unknown']}
                      optionFormatter={(s: string | number): JSX.Element => fm(`diagnosis.opts.yesNoUnknown.${s}`)}
                      editing={!!editing}
                    />
                  </div>
                )}
                {!isNil(formData.document?.typeOfDisease?.[index]?.dis) && (
                  <div style={{ marginBottom: '2rem' }}>
                    <TitleRow name="dis" index={index} handleDelete={openDeleteDialog} />
                    <InputHandler
                      type="Radio"
                      name="dis"
                      formData={{
                        onChange: onChange,
                        document: {
                          dis: formData.document?.typeOfDisease?.[index]?.dis,
                        },
                      }}
                      options={['yes', 'no', 'unknown']}
                      optionFormatter={(s: string | number): JSX.Element => fm(`diagnosis.opts.yesNoUnknown.${s}`)}
                      editing={!!editing}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            editingModeOn={
              newDiagnosis &&
              ((Array.isArray(formData.document.typeOfDisease) && formData.document.typeOfDisease.length < 2) ||
                formData.document.typeOfDisease === undefined)
            }
          />
        ) : undefined}
      </FormSection>
      <ConfirmationDialog
        open={deleteDialogOpen}
        text={fm('general.reallyWantToDelete')}
        confirm={{ callback: deleteConfirmCallback }}
        cancel={{ callback: deleteCancelCallback }}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IDiagnosis>;
  newDiagnosis: boolean;
}

export default MsType;
