import { isEmpty } from 'ramda';
import * as React from 'react';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import { FormContextProvider, HistoryContextProvider } from '../../../containers/FormContextHandler';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import {
  docType,
  getHeaderId,
  mapPatientDoesNotWantRespiratorySupportTherapyEndDates,
  patientHasRespiratoryFailureDiagnosis,
  patientHasSleepApneaDiagnosis,
  TDocument,
} from '../utils';
import Form from './Form';

import PatientDoesNotWantRespiratorySupportTherapyHistory from './HistoryRowData/PatientDoesNotWantRespiratorySupportTherapy';
import OxygenTherapyHistory from './HistoryRowData/OxygenTherapy';
import HFNCTherapyHistory from './HistoryRowData/HFNCTherapy';
import PAPTherapyHistory from './HistoryRowData/PAPTherapy';
import MADTherapyHistory from './HistoryRowData/MADTherapy';
import SurgicalTreatmentHistory from './HistoryRowData/SurgicalTreatment';
import OtherTreatmentHistory from './HistoryRowData/OtherTreatment';
import { actions, TCreationPromiseType } from '../../../store/documents/actions';
import { assertCapabilities, useAppDispatch, useAppSelector } from 'Store/index';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import Icon from 'Components/_NewElements/Icon';
import { fetchAvxPatientLink } from './Form/PAPTherapy/functions';
import { fm } from 'Components/FormatMessage';
import { resMedSyncAction } from 'Store/session';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import Dialog, { IThemedDialog } from 'Components/_NewElements/Dialog';

export const PAPTherapyContext = React.createContext<IPAPTherapyContext>({
  resMedIntegrationAvailable: false,
  resMedIntegrationEnabled: false,
  setResMedIntegrationEnabled: () => '',
  resMedDialogOpen: false,
  setResMedDialogOpen: () => '',
  ecn: undefined,
  totalUsageHours: undefined,
  openInformationDialog: () => '',
});

interface IPAPTherapyContext {
  resMedIntegrationAvailable: boolean;
  resMedIntegrationEnabled: boolean;
  setResMedIntegrationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  resMedDialogOpen: boolean;
  setResMedDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ecn: string | undefined;
  totalUsageHours: string | undefined;
  openInformationDialog: (text: string | JSX.Element, hasTimeout?: boolean) => void;
}

const TreatmentHistory = ({ documents }: { documents: Array<IDiagnosis> }): JSX.Element => {
  return (
    <React.Fragment>
      <PatientDoesNotWantRespiratorySupportTherapyHistory />
      {(patientHasRespiratoryFailureDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <OxygenTherapyHistory />
      )}
      {(patientHasRespiratoryFailureDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <HFNCTherapyHistory />
      )}
      <PAPTherapyHistory />
      {(patientHasSleepApneaDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <MADTherapyHistory />
      )}
      {(patientHasSleepApneaDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <SurgicalTreatmentHistory />
      )}
      <OtherTreatmentHistory />
    </React.Fragment>
  );
};

const Treatment = ({ documents, capabilityGroups = {} }: IOwnProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const createCommit = async (id: string): Promise<TCreationPromiseType> =>
    actions.createCommit({ id, name: 'patientDoesNotWantRespiratorySupportTherapy' })(dispatch);

  const closeCommitWithData = (id: string, data: any & IControlProps) =>
    actions.closeCommit({ id, name: 'patientDoesNotWantRespiratorySupportTherapy' }, data)(dispatch);

  const isEditing = documents.some((d) => d._editing === true);

  React.useEffect(() => {
    const docsWithUpdatedEndDates = mapPatientDoesNotWantRespiratorySupportTherapyEndDates(documents);
    // PDNWRST endDates are only updated if they don't exist, not if there isn't a matching treatment start/decision date
    if (!isEditing) {
      docsWithUpdatedEndDates?.forEach((d) => {
        createCommit(d._id)
          .then(() => {
            closeCommitWithData(d._id, d);
          })
          .catch(() => {
            return;
          });
      });
    }
  }, [isEditing]);

  const visitReasonRetro = useAppSelector((s: IState) => s.session.data?.visitreason === 'retrospectiveDataTransfer');

  const [informationDialogSettings, setInformationDialogSettings] = React.useState<IThemedDialog | null>(null);

  const openInformationDialog = (text: string | JSX.Element, hasTimeout = true) => {
    setInformationDialogSettings({
      open: true,
      children: text,
      onClose: () => setInformationDialogSettings(null),
      closeTimeout: hasTimeout ? 3000 : undefined,
    });
  };

  const resMedIntegrationAvailable = assertCapabilities(['RESMED_AVX_INTEGRATION'], capabilityGroups);
  const [resMedIntegrationEnabled, setResMedIntegrationEnabled] = React.useState<boolean>(false);
  const [ecn, setECN] = React.useState<string | undefined>(undefined);
  const [totalUsageHours, setTotalUsageHours] = React.useState<string | undefined>(undefined);
  const [resMedDialogOpen, setResMedDialogOpen] = React.useState<boolean>(false);

  const resMedSync = React.useCallback(() => resMedSyncAction(dispatch, true), [dispatch]);

  React.useEffect(() => {
    if (resMedIntegrationAvailable && (!resMedIntegrationEnabled || isEditing)) {
      fetchAvxPatientLink()
        .then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            setResMedIntegrationEnabled(!!(res && typeof res === 'object' && !isEmpty(res)));
            setECN(res[0].ecn);
            setTotalUsageHours(res[0].totalUsageHours);
            // Sync session data here when ResMed link is first enabled
            if (isEditing) {
              resMedSync();
            }
          } else {
            setResMedIntegrationEnabled(false);
            if (ecn) setECN(undefined);
            if (totalUsageHours) setTotalUsageHours(undefined);
          }
        })
        .catch(() => {
          return;
        });
    }
  }, [resMedIntegrationEnabled]);

  const resMedContext = {
    resMedIntegrationAvailable,
    resMedIntegrationEnabled,
    setResMedIntegrationEnabled,
    resMedDialogOpen,
    setResMedDialogOpen,
    ecn,
    totalUsageHours,
    openInformationDialog,
  };

  return (
    <FormEditingHandler name="treatment" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="treatment"
            headerId={getHeaderId(documents, editing, view?.viewing)}
            editing={editing}
            editButtons={<div />}
            infoText={
              resMedIntegrationAvailable && docType(documents, editing ?? view?.viewing) === 'papTherapy' ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontWeight: '600' }}>
                    {fm('treatment.papTherapy.resMedIntegration')}{' '}
                    <span style={{ textTransform: 'lowercase' }}>
                      {fm(`treatment.papTherapy.resMed${resMedIntegrationEnabled ? 'InUse' : 'NotInUse'}`)}
                    </span>
                  </div>
                  <ActionButtonRounded
                    text={
                      <Container alignItems="center" justifyContent="space-between">
                        <Item>
                          {resMedIntegrationEnabled ? <Icon icon="mergeType" /> : <Icon icon="sqicon" size={3.1} />}
                        </Item>
                        <Item xs={true}>
                          {fm(`treatment.papTherapy.${resMedIntegrationEnabled ? 'editResMed' : 'connectResMed'}`)}
                        </Item>
                      </Container>
                    }
                    width={26}
                    height={4}
                    fontSize={16}
                    onClick={() => setResMedDialogOpen(true)}
                    disabled={typeof resMedIntegrationEnabled !== 'boolean' || !!view?.viewing}
                  />
                </div>
              ) : undefined
            }
            headerWidth={12}
          />
          <PAPTherapyContext.Provider value={resMedContext}>
            {/** Editing */}
            {editing && documents.find((d: TDocument) => d._id === editing) ? (
              <FormContextProvider context={{ formData, documents, editing }}>
                <Form document={documents.find((d: TDocument) => d._id === editing) as TDocument} />
              </FormContextProvider>
            ) : null}
            {/** Viewing */}
            {view?.viewing && documents.find((d: TDocument) => d._id === view.viewing) ? (
              <FormContextProvider context={{ formData, documents, view }}>
                <Form document={documents.find((d: TDocument) => d._id === view.viewing) as TDocument} />
              </FormContextProvider>
            ) : null}
            {/** History */}
            {!editing && !view?.viewing ? (
              <HistoryContextProvider
                context={{
                  documents: documents,
                  startEdit: startEdit,
                  view: view,
                  additionalData: { visitReasonRetro },
                }}
              >
                <TreatmentHistory documents={documents.filter((d) => d._type === 'diagnosis') as Array<IDiagnosis>} />
              </HistoryContextProvider>
            ) : null}
          </PAPTherapyContext.Provider>
          {informationDialogSettings && (
            <Dialog
              open={informationDialogSettings.open}
              type="info"
              onClose={informationDialogSettings.onClose}
              closeTimeout={informationDialogSettings.closeTimeout}
            >
              {informationDialogSettings.children}
            </Dialog>
          )}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps extends ICapabilityContextProps {
  documents: (
    | TDocument
    | IDiagnosis
    | ISleepApneaFirstVisit
    | IRespiratoryFirstVisit
    | ISleepPolygraphy
    | ISleepStudy
  )[];
}

export default withCapabilities(Treatment);
