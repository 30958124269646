import * as React from 'react';
import { Container, Item } from 'Components/Grid';
import CollapsibleRow from 'Components/CollapsibleRow';
import colors from '../../../config/theme/colors';
import { fm } from 'Components/FormatMessage';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { styled } from '@mui/system';
import SearchResults from '../components/SearchResults';
import { ExtendedSearchField, extendedSearchFields, TESSearchField, TESSearchFields } from '../components/SearchFields';
import SearchSpinner from '../components/SearchSpinner';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { remove } from 'ramda';
import { fetchWithOptions } from 'Utility/fetch';
import { CollapsedContent } from '../components';
import { useAppSelector as useSelector } from 'Store/index';
import { PlatformSelectionDropdown, PlatformSelectionList } from '../components/PlatformSelection';

const StyledSubHeader = styled('div')({
  color: colors.primary,
  fontWeight: 600,
  fontSize: '1.8rem',
});

/** Specific medication - search */
const search = (currentSearchString: string): Promise<any> =>
  fetchWithOptions(
    `/api/medication/search?search=${currentSearchString}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  ).then(
    (res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw { status: res.status, fullResponse: res };
      }
    },
    (error: Error) => {
      throw error;
    },
  );

/** Specific medication - change */
const changeMedication =
  (onChange: (e: TOnChangeValues) => void, medications: 'noCropping' | Array<string>, name: string) =>
  (values: TOnChangeValues): void => {
    const valuesName = Object.keys(values)[0];
    const value = values[valuesName];
    if (valuesName === 'medicationName') {
      if (medications === 'noCropping') onChange({ [name]: [value as string] });
      if (Array.isArray(medications) && !medications.includes(value ? `${value}` : '')) {
        medications.push(value as string);
        onChange({ [name]: medications });
      }
    }
  };

/** Specific medication - remove */
const removeMedication = (
  onChange: (values: TOnChangeValues) => void,
  medications: 'noCropping' | Array<string>,
  index: number,
  name: string,
): void => {
  if (Array.isArray(medications) && medications.length > 1) {
    onChange({ [name]: remove(index, 1, medications) });
  } else if (Array.isArray(medications) && medications.length === 1) {
    // When removing last med, change the value to "noCropping"
    onChange({ [name]: 'noCropping' });
  }
};

const changeSubstance =
  (onChange: (e: TOnChangeValues) => void, substances: 'noCropping' | Array<string>) =>
  (values: TOnChangeValues): void => {
    const valuesName = Object.keys(values)[0];
    if (valuesName !== 'substance') return;
    const value = values[valuesName];
    if (substances === 'noCropping') onChange({ status: 'noCropping', ['substance']: [value as string] });
    if (Array.isArray(substances) && !substances.includes(value ? `${value}` : '')) {
      const newSubstances = [...substances, value];
      onChange({ ['substance']: newSubstances });
    }
  };
const removeSubstance = (
  onChange: (values: TOnChangeValues) => void,
  substances: 'noCropping' | Array<string>,
  index: number,
): void => {
  if (Array.isArray(substances) && substances.length > 1) {
    onChange({ ['substance']: remove(index, 1, substances) });
  } else if (Array.isArray(substances) && substances.length === 1) {
    onChange({ ['substance']: 'noCropping' });
  }
};

/** For collapsed content - default */
const locPath = (platform: string, name: string) =>
  !(
    (platform === 'ms' && name === 'edss') ||
    (platform === 'parkinson' && ['diagnosis', 'currentHoehnYahr'].includes(name))
  )
    ? 'extendedSearch.opts'
    : undefined;

/** For collapsed content - sub fields */
const secondaryLocPath = (name: string) =>
  !['medication', 'substance'].includes(name) ? 'extendedSearch.opts' : undefined;

const getCollapsedContentValueType = (
  value: any,
  fieldName: string,
): 'numberRange' | 'dateRange' | 'objectArray' | undefined => {
  if (['currentHoehnYahr', 'edss'].includes(fieldName)) return undefined;
  if (Array.isArray(value)) {
    if (value.every((v: unknown) => typeof v === 'number')) return 'numberRange';
    if (value.every((v: unknown) => typeof v === 'object')) return 'objectArray';
    return undefined;
  }
  if (typeof value === 'object') {
    if (Object.values(value).some((value) => typeof value === 'number')) return 'numberRange';
    return 'dateRange';
  }
  return undefined;
};

/** Extended search fields and results */
const ExtendedSearch = ({
  state,
  onChange,
  extendedSearch,
  showSearchResults,
  onClickResult,
}: IOwnProps): JSX.Element => {
  const platforms = useSelector((s) => s.session.platforms?.available || []);

  const unclearableFields = ['includeDeceased'];

  const handleClear = (name: string): void => {
    if (!state || !state.searchFields) return;
    const value = state.searchFields[name as keyof typeof state.searchFields];
    if (value) {
      onChange({ [name]: 'noCropping' });
    }
  };

  const searchResults = useSelector((state: IState) => state.patientsearch.searchResults);

  /**
   * These are used to collapse all the search fields when all options are cleared.
   * Takes control of the collapsed prop in CollapsibleRow with collapsed=true and then lets the component do it's own thing again.
   */
  const [collapsed, setCollapsed] = React.useState<true | undefined>(undefined);
  const collapseSearchFields = () => {
    setCollapsed(true);
    setTimeout(() => {
      setCollapsed(undefined);
    }, 100);
  };

  return (
    <Container>
      {state && state.platform && state.platform.length > 0 ? (
        <React.Fragment>
          <Item
            xs={6}
            style={{
              borderRight: `1px solid ${showSearchResults ? colors.gray : 'transparent'}`,
              paddingRight: '4rem',
            }}
          >
            <PlatformSelectionDropdown
              platforms={platforms}
              selectedPlatform={state.platform}
              onChange={(platform) => onChange({ platform })}
            />
            <Container style={{ paddingTop: '1.5rem' }}>
              <Item
                xs={12}
                style={{
                  marginBottom: '1.5rem',
                }}
              >
                <StyledSubHeader>{fm('extendedSearch.searchCriteria')}</StyledSubHeader>
              </Item>
            </Container>
            <div style={{ marginBottom: '2.5rem' }}>
              {extendedSearchFields(state.searchFields)[state.platform].map((field: TESSearchField, index: number) => {
                const value = state.searchFields[field.name as keyof typeof state.searchFields];

                const secondaryValue = (name: string, value: TFieldValue): TFieldValue => {
                  switch (name) {
                    case 'relapseDuringLast12Months': {
                      return value === 'yes' ? state.searchFields.numberOfRelapses : undefined;
                    }
                    default: {
                      return undefined;
                    }
                  }
                };

                return field.condition === false ? undefined : (
                  <CollapsibleRow
                    key={index}
                    title={`extendedSearch.${field.title ? field.title : field.name}`}
                    content={
                      <ExtendedSearchField
                        field={field}
                        platform={state.platform || ''}
                        searchFields={state.searchFields}
                        onChange={onChange}
                        medicationTools={{
                          search,
                          changeMedication,
                          removeMedication,
                        }}
                        substanceTools={{
                          changeSubstance,
                          removeSubstance,
                        }}
                      />
                    }
                    collapsedContent={
                      value && value !== 'noCropping' ? (
                        <CollapsedContent
                          value={value}
                          type={getCollapsedContentValueType(value, field.name)}
                          secondaryValue={secondaryValue(field.name, value)}
                          locPath={locPath(state.platform || '', field.name)}
                          secondaryLocPath={secondaryLocPath(field.name)}
                        />
                      ) : (
                        fm('extendedSearch.opts.noCropping')
                      )
                    }
                    onClear={
                      unclearableFields.includes(field.name)
                        ? undefined
                        : value !== 'noCropping'
                          ? () => handleClear(field.name)
                          : undefined
                    }
                    /** Collapse the field when onClear used */
                    collapseOnClear
                    /** Collapses the search option field when every search option is cleared with clear button */
                    collapsed={unclearableFields.includes(field.name) ? undefined : collapsed}
                  />
                );
              })}
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <ActionButtonRounded
                  width={17}
                  height={4}
                  fontSize={16}
                  text="extendedSearch.clearSearchOptions"
                  onClick={() => {
                    collapseSearchFields();
                    window.scrollTo({ behavior: 'smooth', top: 0 });
                    // Changing the platform also clears the search options
                    onChange({ platform: state.platform });
                  }}
                />
              </div>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                  <ActionButtonRounded
                    width={6}
                    height={4}
                    fontSize={16}
                    text="general.search"
                    onClick={() => {
                      window.scrollTo({ behavior: 'smooth', top: 0 });
                      extendedSearch();
                    }}
                    filled
                  />
                </div>
              </div>
            </div>
          </Item>
          <Item xs={6} style={{ paddingLeft: '4rem' }}>
            {showSearchResults &&
              (state.searchLoading ? (
                <SearchSpinner text="general.extendedSearching" />
              ) : (
                <SearchResults searchResults={searchResults || []} onClickResult={onClickResult} />
              ))}
          </Item>
        </React.Fragment>
      ) : (
        <Item>
          <PlatformSelectionList platforms={platforms} onChange={(platform: Platform) => onChange({ platform })} />
        </Item>
      )}
    </Container>
  );
};

export interface IExtendedSearch {
  platform?: Platform;
  searchFields: TESSearchFields;
  searchLoading: boolean;
}

interface IOwnProps {
  state: IExtendedSearch;
  onChange: (e: TOnChangeValues) => void;
  extendedSearch: () => void;
  showSearchResults: boolean;
  onClickResult: (id?: string) => void;
}

export default ExtendedSearch;
