import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { equals } from 'ramda';
import { timeMonth } from 'd3-time';
import { actions } from '../../store/session';
import { actions as docActions } from '../../store/documents/actions';
import { actions as myActions } from '../../store/myapp/actions';
import { TDispatch } from '../../store';
import { assertCapabilities } from 'Store/index';
import PlatformCapabilities from '../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from '../../containers/CapabilityHandler';

import * as Util from './Utils/';
import {
  ssnBirthday,
  formatPartialDate,
  getSSNAgeInWholeMonths,
  sortDate,
  isPartialDate,
  dateFromPartial,
  sortPartialDate,
  nowPartialDate,
} from 'neuro-utils';

import epilepsyData from './Settings/epilepsy';
import huntingtonData from './Settings/huntington';
import msData from './Settings/ms';
import parkinsonData from './Settings/parkinson';
import sleepApneaAndRespiratoryFailureData from './Settings/sleepApneaAndRespiratoryFailure';
import smaData from './Settings/sma';
import dmdData from './Settings/dmd';

import Graph from '../sq-graphics/Graph/Graph';

import {
  IAddon,
  IDashboardGraphProps,
  IData,
  IDiseaseHistoryData,
  IEvent,
  TGraphSettings,
} from 'Components/sq-graphics';

import {
  convertDiagnosisToTimeline,
  convertExitusToTimeline,
  convertAppointmentToTimeline,
  convertImagingToTimeline,
  convertComorbidityToTimeline,
  convertClinicalStudyToTimeline,
  convertTherapyToTimeline,
  convertSdmtToGraph,
  convertMeasurementsToGraph,
  convertPregnancyToTimeline,
} from './Settings/DataConversion/shared';

import {
  convertMsTypesToTimeline,
  convertSpmsToTimeline,
  convertRelapseAndEarlySymptomToTimeline,
  convertEdssToGraph,
  convertMriBrainToGraph,
  convertInflammatoryDiseaseActivityToTimeline,
  convertStemcellsToTimeline,
  convertMriSpinalToGraph,
} from './Settings/DataConversion/ms';

import {
  convertGeneTestToTimeline,
  convertUpdrsVToTimeline,
  convertLeddToGraph,
  convertUpdrsIIIToGraph,
  convertMocaToGraph,
  convertMmseToGraph,
  convertTmtToGraph,
  convertCdrToGraph,
  convertDbsEffectToGraph,
  convertLevodopaTestToGraph,
  convertThalamotomyToTimeline,
  convertHifuToTimeline,
  convertFbiModToGraph,
} from './Settings/DataConversion/parkinson';

import {
  convertContraceptionToTimeline,
  convertPregnancyToTimeline as convertPregnancyToTimelineEpilepsy,
  convertDietTherapyToTimeline,
  convertSurgicalTreatmentToTimeline,
  convertOtherSymptomToTimeline,
  convertModifiedRankinScaleToGraph,
  convertModifiedChildrensGlobalAssessmentScaleToGraph,
  convertEngelClassificationToGraph,
  convertSeizuresToGraph,
  convertSeizureMedicationToTimeline,
} from './Settings/DataConversion/epilepsy';

import {
  convertTreatmentToTimeline,
  convertClpAndCnpTestsToTimeline,
  convertPapToGraph,
  convertAHIToGraph,
  convert95thPercentilePressureToGraph,
  convert95thPercentileMaskLeakToGraph,
  convertTcCO2MeanToGraph,
  convertOxygenFlowToGraph,
  convertOxygenSaturationToGraph,
  convertFlowRateToGraph,
  convertSupplementalOxygenToGraph,
  convertAiCentralToGraph,
  convertTidalVolume95PercentileToGraph,
  convertRespiratoryRate95PercentileToGraph,
  convertSpontTToGraph,
  convertSpontCToGraph,
  convertTargetVentilationToGraph,
} from './Settings/DataConversion/sleepApnea';

import {
  convertNsaaToGraph,
  convertMfmToGraph,
  convert6mwtToGraph,
  convertChopIntendToGraph,
  convertHinemmToGraph,
  convertHfmseToGraph,
  convertRulmToGraph,
  convertVentilationUseToTimeline,
  convertMobilityAidToTimeline,
  convertMotorFunctionToTimeline,
  convertNutritionToTimeline,
  convertHospitalizationToTimeline,
} from './Settings/DataConversion/smaAndDmd';

import {
  convertUhdrsIndependenceScaleToGraph as convertUhdrsIndependenceScaleToGraph2,
  convertUhdrsFunctionalAssessmentToGraph as convertUhdrsFunctionalAssessmentToGraph2,
  convertUhdrsFunctionalCapacityToGraph as convertUhdrsFunctionalCapacityToGraph2,
  convertUhdrsMotorRatingScaleToGraph1 as convertUhdrsMotorRatingScaleToGraph1x2,
  convertUhdrsMotorRatingScaleToGraph2 as convertUhdrsMotorRatingScaleToGraph2x2,
  convertUhdrsConfidenceIndexToGraph as convertUhdrsConfidenceIndexToGraph2,
  convertPbasToGraph,
} from './Settings/DataConversion/huntington';

import {
  convertBdiAndBaiToGraph,
  convertDesToGraph,
  convertEpisodicSymptomsToGraph,
  convertEq5dToGraph,
  convertEssToGraph,
  convertFsmcToGraph,
  convertFssToGraph,
  convertGicToGraph,
  convertMsisToGraph,
  convertMsnqToGraph,
  convertPatientReportedMedicationsToTimeline,
  convertPatientSelfReportEventsToTimeline,
  convertPdq8ToGraph,
  convertPedsqlFatigueToGraph,
  convertPedsqlNeuromuscularToGraph,
  convertPredssToGraph,
  convertSleepToGraph,
  convertStatusToGraph,
  convertTdcsMyTreatmentsToGraph,
} from './Settings/DataConversion/myapp';

import {
  convertDoctorsOrdersToTimeline,
  convertGafToGraph,
  convertMadrsToGraph,
  convertNumberOfPulsesToGraph,
  convertEffectHarmToGraph,
  convertEffectIntensityToGraph,
  convertSymptomsIntensityOrHarmToGraph,
  convertTreatmentSessionsPerWeekToGraph,
  convertTreatmentsToTimeline,
  convertTreatmentVisitsPerWeekToGraph,
} from './Settings/DataConversion/ninmt';

import { epilepsyGraphDocTypes } from '../../config/platformSettings/epilepsy';
import { huntingtonGraphDocTypes } from '../../config/platformSettings/huntington';
import { parkinsonGraphDocTypes } from '../../config/platformSettings/parkinson';
import { msGraphDocTypes } from '../../config/platformSettings/ms';
import { sleepApneaGraphDocTypes } from '../../config/platformSettings/sleepApnea';
import { smaGraphDocTypes } from '../../config/platformSettings/sma';
import { dmdGraphDocTypes } from '../../config/platformSettings/dmd';
import ninmtData from './Settings/ninmt';
import { INeuroDocument, INeuroGrant, IVaultOrganization } from 'neuro-data-structures';
import { ninmtGraphDocTypes } from '../../config/platformSettings/ninmt';
import CenteredLoadingIndicator from 'Components/CenteredLoadingIndicator';
import { convertMedicationToTimeline } from './Settings/DataConversion/medication';
import { convertDbsToTimeline, convertVnsToTimeline } from './Settings/DataConversion/dbsAndVns';
import { diagnosisPlatform } from '../../config/generalSettings';
import mgravisData from './Settings/mgravis';
import { isLocale } from 'Containers/Localizer';
import {
  convertCnpExamsToTimeline,
  convertDiagnosticTestsToTimeline,
  convertHospitalizationToTimelineMgravis,
  convertMGADLToGraph,
  convertMGCToGraph,
  convertMGFAToTimeline,
  convertPassQuestionToTimeline,
  convertProcedureToTimeline,
  convertSymptomsToTimeline,
} from './Settings/DataConversion/mgravis';
import { mgravisGraphDocTypes } from '../../config/platformSettings/mgravis';
import { IReadonlyDocument } from 'Store/readonly/readonlyGlobals';

const parseLatestNinmtTreatmentDecisionDate = (
  platform: Platform | null,
  sortedAndMergedDocuments: ({ [key: string]: any } & IControlProps)[],
): Date | null => {
  if (platform !== 'ninmt') return null;
  const ninmtTreatmentPeriodDates: Array<PartialDate> = sortedAndMergedDocuments
    .map((d) => (d._type === 'ninmtTreatmentPeriod' ? d.date : undefined))
    .filter((d) => d);
  if (!ninmtTreatmentPeriodDates.length) return null;
  if (ninmtTreatmentPeriodDates.length === 1 && ninmtTreatmentPeriodDates?.[0])
    return dateFromPartial(ninmtTreatmentPeriodDates[0]);
  // Stupid hack below due to typescript not understanding filter(d => d)
  return dateFromPartial(ninmtTreatmentPeriodDates.sort((n1, n2) => sortPartialDate(n2, n1))?.[0] || [2000, 0, 1]);
};

type Props = IOwnProps & IMapStateFromProps & IMapDispatchFromProps;

class DashboardGraph extends React.Component<Props, IOwnState> {
  // Use this to check whether we should perform async functions and state changes
  // State changes should not be performed if the component has unmounted
  private mounted = false;

  private timeframe: Array<Date | undefined> = [];
  private dateOfBirth: Date | null = null;
  private agePoints: IEvent[] = [];

  // for defining whether to show ageYears or ageMonths in topdata title
  // If agemonths < 60 or so, the topdata title will be agemonths
  private ageMonths: number | undefined;

  private latestNinmtTreatmentDecisionDate: Date | null;

  constructor(props: Props) {
    super(props);
    if (this.props.ssn) {
      this.dateOfBirth = ssnBirthday(this.props.ssn);
      this.ageMonths = getSSNAgeInWholeMonths(this.props.ssn);
    }
    this.latestNinmtTreatmentDecisionDate = parseLatestNinmtTreatmentDecisionDate(
      props.platform,
      props.sortedAndMergedDocuments,
    );
    this.state = {
      graphData: null,
      addonData: null,
      loaded: false,
      loading: false,
      width: 134.8,
      graphSettings: {
        topDataAddons: ['hoehnYahr', 'exitus', 'passQuestion', 'mgfa'],
        graphMenusToBeHiddenIfEmpty:
          props.platform === 'sleepApnea'
            ? [
                'deviceUsageHours',
                'pap',
                'ahi',
                'pressure95thPercentile',
                'maskLeak95thPercentile',
                'aiCentral',
                'tidalVolume95Percentile',
                'respiratoryRate95Percentile',
                'spontT',
                'spontC',
                'targetVentilation',
                'tcCO2Mean',
                'oxygenFlow',
                'oxygenFlowPercent',
                'oxygenSaturation',
                'flowRate',
                'supplementalOxygen',
              ]
            : props.platform === 'sma' &&
                this.props.sortedAndMergedDocuments.find((diag) => diag?.diagnosis === 'G12.1')
              ? ['chopintend', 'hinemm']
              : [],
        timeframeLengthOptions: undefined,
        defaultTimeframeLength: 'all',
        defaultTimeframe: undefined,
        graphSettings: {
          helperLines: props.orgSettings?.settings?.graph?.helperLines,
        },
      },
      medicationSettings: props.orgSettings?.settings?.medicationSettings,
      fm: (id: string, values?: { [key: string]: string }): string => props.intl.formatMessage({ id: id }, values),
    };
  }

  public clearData = (): void => {
    this.timeframe = [];
    //this.dateOfBirth = null;
    this.agePoints = [];
    this.latestNinmtTreatmentDecisionDate = parseLatestNinmtTreatmentDecisionDate(
      this.props.platform,
      this.props.sortedAndMergedDocuments,
    );
    this.setState({
      graphData: null,
      addonData: null,
      loaded: false,
      graphSettings: {
        topDataAddons: ['hoehnYahr', 'exitus', 'passQuestion', 'mgfa'],
        graphMenusToBeHiddenIfEmpty:
          this.props.platform === 'sleepApnea'
            ? [
                'deviceUsageHours',
                'pap',
                'ahi',
                'pressure95thPercentile',
                'maskLeak95thPercentile',
                'aiCentral',
                'tidalVolume95Percentile',
                'respiratoryRate95Percentile',
                'spontT',
                'spontC',
                'targetVentilation',
                'tcCO2Mean',
                'oxygenFlow',
                'oxygenFlowPercent',
                'oxygenSaturation',
                'flowRate',
                'supplementalOxygen',
              ]
            : this.props.platform === 'ms'
              ? ['predss', 'status', 'eq5d', 'msis29', 'fss', 'msnq', 'fsmc']
              : this.props.platform === 'sma' &&
                  this.props.sortedAndMergedDocuments.find((diag) => diag?.diagnosis === 'G12.1')
                ? ['chopintend', 'hinemm']
                : [],
        timeframeLengthOptions: undefined,
        defaultTimeframeLength: 'all',
        defaultTimeframe: undefined,
        graphSettings: {
          helperLines: this.props.orgSettings?.settings?.graph?.helperLines,
        },
      },
      medicationSettings: this.props.orgSettings?.settings?.medicationSettings,
      fm: (id: string, values?: { [key: string]: string }): string => this.props.intl.formatMessage({ id: id }, values),
    });
  };

  //public fm = (id: string, values?: { [key: string]: string }): string => this.formatMessage({ id: id }, values);

  /* Loads disease spesific data for graph rendering */
  loadDefaultData = (platform: string): IDiseaseHistoryData => {
    switch (platform) {
      case 'epilepsy': {
        return epilepsyData(
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          this.state.medicationSettings,
        );
      }
      case 'parkinson': {
        return parkinsonData(
          this.props.sortedAndMergedDocuments,
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          this.state.medicationSettings,
        );
      }
      case 'huntington': {
        return huntingtonData(
          !!this.props.sortedAndMergedDocuments.find((diag) => diag?.diagnosis === 'Z82'),
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          this.state.medicationSettings,
        );
      }
      case 'ms': {
        return msData(
          undefined,
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          this.state.medicationSettings,
        );
      }
      case 'sma': {
        const sma1 = this.props.sortedAndMergedDocuments.find((diag) => diag?.diagnosis === 'G12.0');
        const hasChopIntend = this.props.documents.find((d) => d.documentType === 'chop_intend');
        const hasHineMM = this.props.documents.find((d) => d.documentType === 'hine_mm');
        return smaData(
          this.ageMonths,
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          !!sma1,
          !!hasChopIntend,
          !!hasHineMM,
          this.state.medicationSettings,
        );
      }
      case 'dmd': {
        return dmdData(
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          this.state.medicationSettings,
        );
      }
      case 'sleepApnea': {
        return sleepApneaAndRespiratoryFailureData(
          this.props.sortedAndMergedDocuments,
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          this.state.medicationSettings,
        );
      }
      case 'ninmt': {
        return ninmtData(
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          this.state.medicationSettings,
        );
      }
      case 'mgravis': {
        return mgravisData(
          this.state.fm,
          isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
          this.state.medicationSettings,
        );
      }
      default:
        return { graphData: { common: {} }, addonData: {} };
    }
  };

  /* Creates Date from PartialDate and collects timeframe start and end points as well as agePoints if needed */
  dateFromPartialUpdateTimeFrame = (date?: PartialDate, time?: Time, marker?: true): Date => {
    // If no date given (retrospective) return current date. Whether the event should be shown at all is up every document type to specify separately
    if (!isPartialDate(date)) return new Date();

    const rDate: Date = Util.dateFromPartial(date, time);
    if (date?.[0]) {
      const dateCeiling = dateFromPartial([nowPartialDate()[0] + 1, 12, 31]);
      if (
        rDate.getTime() <= dateCeiling.getTime() &&
        (!this.timeframe[0] || rDate.getTime() < this.timeframe[0].getTime())
      ) {
        this.timeframe[0] = rDate;
      }
      if (
        rDate.getTime() <= dateCeiling.getTime() &&
        (!this.timeframe[1] || rDate.getTime() > this.timeframe[1].getTime())
      ) {
        this.timeframe[1] = rDate;
      }
    }
    if (this.props.platform && this.dateOfBirth && marker) {
      const ageMonth: number = timeMonth.count(this.dateOfBirth, rDate);
      this.agePoints.push({
        date: rDate,
        eventType: 'text',
        title: ageMonth.toString(),
        description: formatPartialDate(date),
      });
    }

    return rDate;
  };

  /* Pretreatment of documents to unified data with Dates instead of PartialDates for the graph component to use */
  loadDataFromDocs = (): void => {
    const convertedData = this.loadDefaultData(this.props.platform || '');
    if (convertedData.graphDataGroups || convertedData.graphMenuSettings)
      this.setState({
        graphDataGroups: convertedData.graphDataGroups || null,
        graphMenuSettings: convertedData.graphMenuSettings || null,
      });

    // Mapataan listaan kaikki dokumenttityypit
    const docTypes = Array.from(new Set(this.props.documents.map((d) => d.documentType)));
    let platformFilteredDocTypes: string[] = [];

    switch (this.props.platform) {
      case 'ms': {
        platformFilteredDocTypes = docTypes.filter((dt) => msGraphDocTypes.includes(dt));
        break;
      }
      case 'parkinson': {
        platformFilteredDocTypes = docTypes.filter((dt) => parkinsonGraphDocTypes.includes(dt));
        break;
      }
      case 'sma': {
        platformFilteredDocTypes = docTypes.filter((dt) => smaGraphDocTypes.includes(dt));
        break;
      }
      case 'dmd': {
        platformFilteredDocTypes = docTypes.filter((dt) => dmdGraphDocTypes.includes(dt));
        break;
      }
      case 'huntington': {
        platformFilteredDocTypes = docTypes.filter((dt) => huntingtonGraphDocTypes.includes(dt));
        break;
      }
      case 'epilepsy': {
        platformFilteredDocTypes = docTypes.filter((dt) => epilepsyGraphDocTypes.includes(dt));
        break;
      }
      case 'sleepApnea': {
        platformFilteredDocTypes = docTypes.filter((dt) => sleepApneaGraphDocTypes.includes(dt));
        break;
      }
      case 'ninmt': {
        platformFilteredDocTypes = docTypes.filter((dt) => ninmtGraphDocTypes.includes(dt));
        break;
      }
      case 'mgravis': {
        platformFilteredDocTypes = docTypes.filter((dt) => mgravisGraphDocTypes.includes(dt));
        break;
      }
      default: {
        platformFilteredDocTypes = docTypes;
        break;
      }
    }

    const allDocumentsMerged = this.props.sortedAndMergedDocuments;

    const ninmtTreatmentPeriodDocs = allDocumentsMerged.filter(
      (d) => d._type === 'ninmtTreatmentPeriod',
    ) as ININMTTreatmentPeriod[];

    const measurementDocs = allDocumentsMerged.filter((d) => d._type === 'measurement') as IMeasurement[];

    const { readonlyDocuments = [] } = this.props;

    const resMedTreatmentResponses = readonlyDocuments.filter((d) => d._type === 'resMedTreatmentResponse');
    const resMedDevices = readonlyDocuments.filter((d) => d._type === 'resMedDevice');
    const resMedSettings = readonlyDocuments.filter((d) => d._type === 'resMedSetting');

    // Loopataan dokumenttityypit läpi => filtteröidään aina dokumenteista ne tietyn tyyppiset dokumentit
    const documentHandleLoop = (docType: string): void => {
      // Combine relapse and msSymptoms documents into one array
      const relapseAndEarlySymptomsPredicate = (d: IControlProps): boolean =>
        (docType === 'relapse' || docType === 'msSymptoms') && (d._type === 'relapse' || d._type === 'msSymptoms');

      const medicationAndNoMedicationPeriodAndOtherTreatmentPredicate = (d: IControlProps): boolean =>
        this.props.platform === 'ms' &&
        (docType === 'medication' || docType === 'periodWithoutMedication' || docType === 'otherTreatment') &&
        (d._type === 'medication' || d._type === 'periodWithoutMedication' || d._type === 'otherTreatment');

      const documents: Array<IControlProps> = allDocumentsMerged.filter((d) => {
        return (
          relapseAndEarlySymptomsPredicate(d) ||
          medicationAndNoMedicationPeriodAndOtherTreatmentPredicate(d) ||
          d._type === docType
        );
      });

      // Dokumenttityypin mukaan tehdään se mitä halutaan.
      /**
       * TODO: Organize this switch-case
       */
      switch (docType) {
        case 'diagnosis': {
          const platformDiagnoses = (documents as IDiagnosis[]).filter(
            (d) => diagnosisPlatform[d.diagnosis] === this.props.platform,
          );
          const diagnoses = convertDiagnosisToTimeline(
            platformDiagnoses,
            this.props.platform,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          diagnoses &&
            convertedData.addonData?.diagnosis?.addons &&
            convertedData.addonData.diagnosis.addons.push(...diagnoses);

          if (this.props.platform === 'ms') {
            // If ms, push ms types to graph
            const msTypes = convertMsTypesToTimeline(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
            msTypes &&
              convertedData.addonData?.diagnosis?.addons &&
              convertedData.addonData.diagnosis.addons.push(...msTypes);

            // SMPS Criteria
            const spms = convertSpmsToTimeline(
              documents,
              this.props.documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            spms &&
              convertedData.addonData?.diagnosis?.addons &&
              convertedData.addonData.diagnosis.addons.push(...spms);
          }
          break;
        }
        case 'ninmtTreatmentPeriod': {
          ninmtTreatmentPeriodDocs.forEach((d) => {
            this.dateFromPartialUpdateTimeFrame(d.date);
          });
          break;
        }
        case 'medication':
        case 'periodWithoutMedication': {
          // Determine the selected organization for medication filtering
          const selectedOrg = this.props.selectedMedicationOrg || this.props.org;

          // Filter documents that belong to the selected organization or were owned by it in the past
          const medicationDocsForOrg = documents.filter(
            (d) =>
              d._ownerOrg === selectedOrg ||
              (d._pastOwners && Object.keys(d._pastOwners).some((org) => org === selectedOrg)),
          );

          // Convert medication documents to timeline format
          const medications = convertMedicationToTimeline(
            medicationDocsForOrg,
            allDocumentsMerged,
            this.dateFromPartialUpdateTimeFrame,
            this.props.platform,
            this.state.medicationSettings,
            this.state.fm,
          );

          // NEUR-3809: Zolgensma is wanted to be drawn in currentMedications
          // Function to check if a medication is Zolgensma
          const isZolgensma = (id: string): boolean => id.toLowerCase().includes('zolgensma');

          // Filter current medications based on platform and medication status
          const currentMedications =
            medications?.filter(
              (m) =>
                (this.props.platform === 'ms' && (m.id === 'noMedicationPeriod' || m.id === 'dVitamin')) ||
                m.items?.some((i) => !i.end || isZolgensma(m.id)),
            ) ?? [];

          // Filter ended medications, exclude specific ids, and sort by ending date
          const endedMedications =
            medications
              ?.filter(
                (m) =>
                  m.id !== 'noMedicationPeriod' &&
                  m.id !== 'dVitamin' &&
                  m.items?.every((i) => i.end) &&
                  !isZolgensma(m.id),
              )
              .sort((s1, s2) => {
                const s1LastItemEnd = s1.items?.[s1.items.length - 1]?.end;
                const s2LastItemEnd = s2.items?.[s2.items.length - 1]?.end;
                return sortDate(s2LastItemEnd, s1LastItemEnd);
              }) ?? [];

          // Combine current and ended medications
          const allMedications = [...currentMedications, ...endedMedications];

          // Prevent re-handling of 'noMedicationPeriod' and 'dVitamin' if already handled
          if (
            this.props.platform === 'ms' &&
            docType === 'periodWithoutMedication' &&
            allMedications.some((m) => m.id !== 'noMedicationPeriod' && m.id !== 'dVitamin')
          ) {
            break;
          }

          // Add dynamic event legend to expansion panel row on Epilepsy platform
          if (this.props.platform === 'epilepsy') {
            const secondaryTitleExists = medications?.some(
              (medication) =>
                Array.isArray(medication.events) && medication.events?.some((event) => event.secondaryTitle),
            );

            if (secondaryTitleExists) {
              convertedData.addonData.medications.legend = {
                type: 'dynamic',
                titles: [this.state.fm('graph.mgPerDay'), this.state.fm('graph.mgKgPerDay')],
              };
            }
          }

          // Add medications to the appropriate data structure based on platform
          if (this.props.platform === 'ms' && convertedData?.addonData?.medications?.addons) {
            convertedData.addonData.medications.addons.push(...allMedications);
          } else {
            if (currentMedications && convertedData?.addonData?.medications?.addons) {
              convertedData.addonData.medications.addons.push(...currentMedications);
            }
            if (endedMedications && convertedData?.addonData?.endedMedications?.addons) {
              convertedData.addonData.endedMedications.addons.push(...endedMedications);
            }
          }

          // Convert LEDD data and add it to the graph data
          const ledds = convertLeddToGraph(medicationDocsForOrg, this.dateFromPartialUpdateTimeFrame, this.state.fm);

          if (ledds && convertedData.graphData?.common?.ledd?.data) {
            convertedData.graphData.common.ledd.data.push(...ledds);
          }

          break;
        }
        case 'papTherapy': {
          const papTherapyDocs = allDocumentsMerged.filter((d) => d._type === 'papTherapy');
          const sleepPolygraphyDocs = allDocumentsMerged.filter((d) => d._type === 'sleepPolygraphy');
          const sleepStudyDocs = allDocumentsMerged.filter((d) => d._type === 'sleepStudy');
          const pap = convertPapToGraph(papTherapyDocs, [], this.dateFromPartialUpdateTimeFrame, this.state.fm);
          pap &&
            convertedData.graphData?.clinicalMetersPap?.pap?.data &&
            convertedData.graphData.clinicalMetersPap.pap.data.length === 0 &&
            convertedData.graphData.clinicalMetersPap.pap.data.push(...pap);

          const ahi = convertAHIToGraph(
            papTherapyDocs,
            sleepPolygraphyDocs,
            sleepStudyDocs,
            [],
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          // Check here that the length of the data is 0 so that no duplicate data is pushed into the array
          ahi &&
            convertedData.graphData?.clinicalMetersPap?.ahi?.data &&
            convertedData.graphData.clinicalMetersPap.ahi.data.length === 0 &&
            convertedData.graphData.clinicalMetersPap.ahi.data.push(...ahi);

          const pressure95thPercentile = convert95thPercentilePressureToGraph(
            papTherapyDocs,
            allDocumentsMerged,
            [],
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          pressure95thPercentile &&
            convertedData.graphData?.clinicalMetersPap?.pressure95thPercentile?.data &&
            convertedData.graphData.clinicalMetersPap.pressure95thPercentile.data.length === 0 &&
            convertedData.graphData.clinicalMetersPap.pressure95thPercentile.data.push(...pressure95thPercentile);

          const maskLeak95thPercentile = convert95thPercentileMaskLeakToGraph(
            papTherapyDocs,
            [],
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          maskLeak95thPercentile &&
            convertedData.graphData?.clinicalMetersPap?.maskLeak95thPercentile?.data &&
            convertedData.graphData.clinicalMetersPap.maskLeak95thPercentile.data.length === 0 &&
            convertedData.graphData.clinicalMetersPap.maskLeak95thPercentile.data.push(...maskLeak95thPercentile);

          const treatments = convertTreatmentToTimeline(
            documents,
            [],
            [],
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          treatments &&
            convertedData.addonData?.treatments?.addons &&
            convertedData.addonData.treatments.addons.push(...treatments);
          break;
        }
        case 'sleepPolygraphy':
        case 'sleepStudy':
        case 'spirometry':
        case 'nightOximetry':
        case 'carbonDioxideMeasurement': {
          /**
           * TODO: refactor this
           */
          // Push AHI data here in case there are no PAP therapy docs - not the best solution
          const papTherapyDocs = allDocumentsMerged.filter((d) => d._type === 'papTherapy');
          const madDocs = allDocumentsMerged.filter((d) => d._type === 'madTherapy') as IMADTherapy[];
          if (papTherapyDocs.length === 0) {
            const sleepPolygraphyDocs = allDocumentsMerged.filter((d) => d._type === 'sleepPolygraphy');
            const sleepStudyDocs = allDocumentsMerged.filter((d) => d._type === 'sleepStudy');
            const ahi = convertAHIToGraph(
              [],
              sleepPolygraphyDocs,
              sleepStudyDocs,
              [],
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            ahi &&
              convertedData.graphData?.common?.ahi?.data &&
              convertedData.graphData.common.ahi.data.length === 0 &&
              convertedData.graphData.common.ahi.data.push(...ahi);
          }
          const clpAndCnpTests = convertClpAndCnpTestsToTimeline(
            documents,
            madDocs,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          clpAndCnpTests &&
            convertedData.addonData.clpAndCnpTests?.addons &&
            convertedData.addonData.clpAndCnpTests?.addons.push(...clpAndCnpTests);
          if (docType === 'carbonDioxideMeasurement') {
            const tcC02Mean = convertTcCO2MeanToGraph(
              documents,
              allDocumentsMerged,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            tcC02Mean &&
              convertedData.graphData?.common?.tcCO2Mean?.data &&
              convertedData.graphData.common.tcCO2Mean.data.push(...tcC02Mean);
          }
          break;
        }
        case 'patientDoesNotWantRespiratorySupportTherapy':
        case 'oxygenTherapy':
        case 'hfncTherapy':
        case 'madTherapy':
        case 'otherTreatment': {
          // If there are no medication or periodWithoutMedication documents, add D-vitamin to addons here
          if (this.props.platform === 'ms' && convertedData?.addonData?.medications?.addons) {
            const medications = convertMedicationToTimeline(
              documents,
              allDocumentsMerged,
              this.dateFromPartialUpdateTimeFrame,
              this.props.platform,
              this.state.medicationSettings,
              this.state.fm,
            );

            if (docType === 'otherTreatment' && medications?.some((m) => m.id !== 'dVitamin')) {
              break;
            }

            const dVitamins = medications?.filter((m) => m.id === 'dVitamin') ?? [];

            if (dVitamins.length > 0) convertedData.addonData.medications.addons.push(...dVitamins);
          }
          const treatments = convertTreatmentToTimeline(
            documents,
            [],
            [],
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          treatments &&
            convertedData.addonData?.treatments?.addons &&
            convertedData.addonData.treatments.addons.push(...treatments);

          const oxygenTherapyDocs = allDocumentsMerged.filter((d) => d._type === 'oxygenTherapy');
          const hfncTherapyDocs = allDocumentsMerged.filter((d) => d._type === 'hfncTherapy');

          const oxygenFlow = {
            title: 'oxygenFlow',
            data: (
              convertOxygenFlowToGraph(
                oxygenTherapyDocs,
                allDocumentsMerged,
                this.dateFromPartialUpdateTimeFrame,
                this.state.fm,
              ) ?? []
            ).filter((data) => data && !data.id.includes('Percent')),
          };

          const oxygenFlowPercent = {
            title: 'oxygenFlowPercent',
            data: (
              convertOxygenFlowToGraph(
                oxygenTherapyDocs,
                allDocumentsMerged,
                this.dateFromPartialUpdateTimeFrame,
                this.state.fm,
              ) ?? []
            ).filter((data) => data && data.id.includes('Percent')),
          };

          const oxygenSaturation = {
            title: 'oxygenSaturation',
            data: convertOxygenSaturationToGraph(
              oxygenTherapyDocs,
              allDocumentsMerged,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            ),
          };
          const flowRate = {
            title: 'flowRate',
            data: convertFlowRateToGraph(
              hfncTherapyDocs,
              allDocumentsMerged,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            ),
          };
          const supplementalOxygen = {
            title: 'supplementalOxygen',
            data: convertSupplementalOxygenToGraph(
              hfncTherapyDocs,
              allDocumentsMerged,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            ),
          };

          [oxygenFlow, oxygenFlowPercent, oxygenSaturation, flowRate, supplementalOxygen].forEach((o) => {
            const title = o.title as keyof typeof convertedData.graphData.common;
            o.data &&
              convertedData.graphData?.common?.[title]?.data &&
              convertedData.graphData?.common?.[title]?.data.length === 0 &&
              convertedData.graphData?.common?.[title]?.data.push(...o.data);
          });
          break;
        }
        case 'nsaa': {
          const nsaas = convertNsaaToGraph(documents, this.dateFromPartialUpdateTimeFrame);
          nsaas &&
            convertedData.graphData?.common?.nsaa?.data &&
            convertedData.graphData.common.nsaa.data.push(...nsaas);
          break;
        }
        case 'mfm': {
          const mfm20s = convertMfmToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm, 'mfm20');
          mfm20s &&
            convertedData.graphData?.common?.mfm20?.data &&
            convertedData.graphData.common.mfm20.data.push(...mfm20s);
          const mfm32s = convertMfmToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm, 'mfm32');
          mfm32s &&
            convertedData.graphData?.common?.mfm32?.data &&
            convertedData.graphData.common.mfm32.data.push(...mfm32s);
          break;
        }
        case 'mwt': {
          const _6mwts = convert6mwtToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          _6mwts &&
            convertedData.graphData?.common?._6mwt?.data &&
            convertedData.graphData.common._6mwt.data.push(..._6mwts);
          break;
        }
        case 'chop_intend': {
          const chopIntends = convertChopIntendToGraph(documents, this.dateFromPartialUpdateTimeFrame);
          chopIntends &&
            convertedData.graphData?.common?.chopintend?.data &&
            convertedData.graphData.common.chopintend.data.push(...chopIntends);
          break;
        }
        case 'hine_mm': {
          const hineMMs = convertHinemmToGraph(documents, this.dateFromPartialUpdateTimeFrame);
          hineMMs &&
            convertedData.graphData?.common?.hinemm?.data &&
            convertedData.graphData.common.hinemm.data.push(...hineMMs);
          break;
        }
        case 'hfms_e': {
          const hfmses = convertHfmseToGraph(documents, this.dateFromPartialUpdateTimeFrame);
          hfmses &&
            convertedData.graphData?.common?.hfmse?.data &&
            convertedData.graphData.common.hfmse.data.push(...hfmses);
          break;
        }
        case 'rulm': {
          const rulms = convertRulmToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          rulms &&
            convertedData.graphData?.common?.rulm?.data &&
            convertedData.graphData.common.rulm.data.push(...rulms);
          break;
        }
        case 'measurement': {
          if (convertedData.graphData?.common?.weights) {
            const weights = convertMeasurementsToGraph(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
              'weights',
            );
            weights && convertedData.graphData.common.weights.data.push(...weights);
          }

          if (convertedData.graphData?.common?.heights) {
            const heights = convertMeasurementsToGraph(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
              'heights',
            );
            heights && convertedData.graphData.common.heights.data.push(...heights);
          }

          if (convertedData.graphData.common.bmi) {
            const bmis = convertMeasurementsToGraph(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
              'bmi',
            );
            bmis && convertedData.graphData.common.bmi.data.push(...bmis);
          }

          const ybocs = convertMeasurementsToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            'ybocs',
            measurementDocs,
          );
          ybocs &&
            convertedData.graphData?.common?.ybocs?.data &&
            convertedData.graphData.common.ybocs.data.push(...ybocs);

          const madrsDocs = this.props.documents.filter((d) => d.documentType === 'madrs');
          const gafDocs = this.props.documents.filter((d) => d.documentType === 'gaf');

          // MADRS and GAF convert functions called here if no 'madrs' / 'gaf' documentTypes found, but in measurement documents those are filled
          if (madrsDocs.length === 0) {
            const madrs = convertMadrsToGraph(
              measurementDocs,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
              measurementDocs,
              ninmtTreatmentPeriodDocs,
            );
            madrs &&
              convertedData.graphData?.common?.madrs?.data &&
              convertedData.graphData.common.madrs.data.push(...madrs);
          }
          if (gafDocs.length === 0) {
            const gaf = convertGafToGraph(
              measurementDocs,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
              measurementDocs,
              ninmtTreatmentPeriodDocs,
            );
            gaf && convertedData.graphData?.common?.gaf?.data && convertedData.graphData.common.gaf.data.push(...gaf);
          }
          break;
        }
        case 'updrs_iii': {
          const updrsIIIs = convertUpdrsIIIToGraph(documents, this.dateFromPartialUpdateTimeFrame);
          updrsIIIs &&
            convertedData.graphData?.common?.updrsIII?.data &&
            convertedData.graphData.common.updrsIII.data.push(...updrsIIIs);
          break;
        }
        case 'moca': {
          const mocas = convertMocaToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          mocas &&
            convertedData.graphData?.common?.moca?.data &&
            convertedData.graphData.common.moca.data.push(...mocas);
          break;
        }
        case 'mmse': {
          const mmses = convertMmseToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          mmses &&
            convertedData.graphData?.common?.mmse?.data &&
            convertedData.graphData.common.mmse.data.push(...mmses);
          break;
        }
        case 'tmt': {
          const tmts = convertTmtToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          tmts && convertedData.graphData?.common?.tmt?.data && convertedData.graphData.common.tmt.data.push(...tmts);
          break;
        }
        case 'cdr': {
          const cdr = convertCdrToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          cdr && convertedData.graphData?.common?.cdr?.data && convertedData.graphData.common.cdr.data.push(...cdr);
          break;
        }
        case 'sdmt': {
          const sdmts = convertSdmtToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          sdmts &&
            convertedData.graphData?.common?.sdmt?.data &&
            convertedData.graphData.common.sdmt.data.push(...sdmts);
          break;
        }
        case 'dbsEffect': {
          const updrsDocs = allDocumentsMerged.filter((d) => d._type === 'updrs_iii');
          const dbsDocs = allDocumentsMerged.filter((d) => d._type === 'dbs');
          const dbsEffect = convertDbsEffectToGraph(
            documents,
            updrsDocs,
            dbsDocs,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          dbsEffect &&
            convertedData.graphData?.common?.dbsEffect?.data &&
            convertedData.graphData.common.dbsEffect.data.push(...dbsEffect);
          break;
        }
        case 'levodopatest': {
          const updrsDocs = allDocumentsMerged.filter((d) => d._type === 'updrs_iii');
          const levodopaTest = convertLevodopaTestToGraph(
            documents,
            updrsDocs,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          levodopaTest &&
            convertedData.graphData?.common?.dbsEffect?.data &&
            convertedData.graphData.common.dbsEffect.data.push(...levodopaTest);
          break;
        }
        case 'neurologicalStatusAndEDSS': {
          const edss = convertEdssToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          edss && convertedData.graphData?.common?.edss?.data && convertedData.graphData.common.edss.data.push(...edss);
          break;
        }
        case 'mri': {
          const imagings = convertImagingToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            this.props.platform,
          );
          if (this.props.platform === 'ms') {
            imagings &&
              convertedData?.addonData?.imaging?.addons &&
              convertedData.addonData.imaging.addons.push(...imagings);

            // Brain
            const mriBrain = convertMriBrainToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
            mriBrain &&
              convertedData.graphData?.common?.mriBrain?.data &&
              convertedData.graphData.common.mriBrain.data.push(...mriBrain);

            // Spinal
            const mriSpinal = convertMriSpinalToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
            mriSpinal &&
              convertedData.graphData?.common?.mriSpinal?.data &&
              convertedData.graphData.common.mriSpinal.data.push(...mriSpinal);
          } else if (this.props.platform === 'parkinson' || this.props.platform === 'sma') {
            imagings &&
              convertedData?.addonData?.studies?.addons &&
              convertedData.addonData.studies.addons.push(...imagings);
          } else {
            imagings &&
              convertedData?.addonData?.imaging?.addons &&
              convertedData.addonData.imaging.addons.push(...imagings);
          }
          break;
        }
        case 'uhdrsIndependenceScale': {
          const uhdrsIndeps = convertUhdrsIndependenceScaleToGraph2(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          uhdrsIndeps &&
            convertedData.graphData?.common?.uhdrsIndependenceScale?.data &&
            convertedData.graphData.common.uhdrsIndependenceScale.data.push(...uhdrsIndeps);
          break;
        }
        case 'uhdrsFunctionalAssessment': {
          const uhdrsFAs = convertUhdrsFunctionalAssessmentToGraph2(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          uhdrsFAs &&
            convertedData.graphData?.common?.uhdrsFunctionalAssessment?.data &&
            convertedData.graphData.common.uhdrsFunctionalAssessment.data.push(...uhdrsFAs);
          break;
        }
        case 'uhdrsFunctionalCapacity': {
          const uhdrsFCs = convertUhdrsFunctionalCapacityToGraph2(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          uhdrsFCs &&
            convertedData.graphData?.common?.uhdrsFunctionalCapacity?.data &&
            convertedData.graphData.common.uhdrsFunctionalCapacity.data.push(...uhdrsFCs);
          break;
        }
        case 'uhdrsMotorRatingScale': {
          const uhdrsMotor1s = convertUhdrsMotorRatingScaleToGraph1x2(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          uhdrsMotor1s &&
            convertedData.graphData?.common?.uhdrsMotorRatingScale1?.data &&
            convertedData.graphData.common.uhdrsMotorRatingScale1.data.push(...uhdrsMotor1s);
          const uhdrsMotor2s = convertUhdrsMotorRatingScaleToGraph2x2(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          uhdrsMotor2s &&
            convertedData.graphData?.common?.uhdrsMotorRatingScale2?.data &&
            convertedData.graphData.common.uhdrsMotorRatingScale2.data.push(...uhdrsMotor2s);
          break;
        }
        case 'uhdrsConfidenceIndex': {
          const uhdrsDCLs = convertUhdrsConfidenceIndexToGraph2(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          uhdrsDCLs &&
            convertedData.graphData?.common?.uhdrsDiagnosticConfidenceLevel?.data &&
            convertedData.graphData.common.uhdrsDiagnosticConfidenceLevel.data.push(...uhdrsDCLs);
          break;
        }
        case 'pbaS': {
          const pbaSs = convertPbasToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          pbaSs &&
            convertedData.graphData?.common?.pbaS?.data &&
            convertedData.graphData.common.pbaS.data.push(...pbaSs);
          break;
        }
        case 'modifiedRankinScale': {
          const mrss = convertModifiedRankinScaleToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          mrss &&
            convertedData.graphData?.common?.modifiedRankinScale?.data &&
            convertedData.graphData.common.modifiedRankinScale.data.push(...mrss);
          break;
        }
        case 'modifiedChildrensGlobalAssessmentScale': {
          const mcgas = convertModifiedChildrensGlobalAssessmentScaleToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          mcgas &&
            convertedData.graphData?.common?.modifiedChildrensGlobalAssessmentScale?.data &&
            convertedData.graphData.common.modifiedChildrensGlobalAssessmentScale.data.push(...mcgas);
          break;
        }
        case 'doctorsOrders': {
          const doctorsOrders = convertDoctorsOrdersToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          doctorsOrders &&
            convertedData.addonData?.doctorsOrders?.addons &&
            convertedData.addonData.doctorsOrders.addons.push(...doctorsOrders);
          const numberOfPulses = convertNumberOfPulsesToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            //ninmtTreatmentPeriodDocs,
          );
          numberOfPulses &&
            convertedData.graphData?.common?.numberOfPulses?.data &&
            convertedData.graphData.common.numberOfPulses.data.push(...numberOfPulses);
          break;
        }
        case 'madrs': {
          const madrs = convertMadrsToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            measurementDocs,
            ninmtTreatmentPeriodDocs,
          );
          madrs &&
            convertedData.graphData?.common?.madrs?.data &&
            convertedData.graphData.common.madrs.data.push(...madrs);
          break;
        }
        case 'gaf': {
          const gaf = convertGafToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            measurementDocs,
            ninmtTreatmentPeriodDocs,
          );
          gaf && convertedData.graphData?.common?.gaf?.data && convertedData.graphData.common.gaf.data.push(...gaf);
          break;
        }
        case 'surgicalTreatment': {
          if (this.props.platform === 'sleepApnea') {
            const treatments = convertTreatmentToTimeline(
              documents,
              [],
              [],
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            treatments &&
              convertedData.addonData?.treatments?.addons &&
              convertedData.addonData.treatments.addons.push(...treatments);
            break;
          }
          if (this.props.platform === 'epilepsy') {
            const surgicalTreatment = convertSurgicalTreatmentToTimeline(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            surgicalTreatment &&
              convertedData.addonData.treatments?.addons &&
              convertedData.addonData.treatments.addons.push(...surgicalTreatment);
            const engels = convertEngelClassificationToGraph(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            engels &&
              convertedData.graphData?.common?.engelClassification?.data &&
              convertedData.graphData.common.engelClassification.data.push(...engels);
          }
          break;
        }
        case 'seizure': {
          const seizuresPerDay = convertSeizuresToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            'daily',
          );
          seizuresPerDay &&
            convertedData.graphData?.common?.seizuresPerDay?.data &&
            convertedData.graphData.common.seizuresPerDay.data.push(...seizuresPerDay);
          const seizuresTotal = convertSeizuresToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            'total',
          );
          seizuresTotal &&
            convertedData.graphData?.common?.seizuresTotal?.data &&
            convertedData.graphData.common.seizuresTotal.data.push(...seizuresTotal);
          const seizuresByType = convertSeizuresToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            'byType',
          );
          seizuresByType &&
            convertedData.graphData?.common?.seizuresByType?.data &&
            convertedData.graphData?.common?.seizuresByType?.data.push(...seizuresByType);
          break;
        }
        case 'dbs': {
          if (this.props.platform === 'parkinson' || this.props.platform === 'epilepsy') {
            const dbsTreatments = convertDbsToTimeline(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
              this.props.platform,
            );
            if (this.props.platform === 'parkinson') {
              dbsTreatments &&
                convertedData.addonData?.dbs?.addons &&
                convertedData.addonData.dbs.addons.push(...dbsTreatments);
            }
            if (this.props.platform === 'epilepsy') {
              dbsTreatments &&
                convertedData.addonData?.treatments?.addons &&
                convertedData.addonData.treatments.addons.push(...dbsTreatments);
            }
          }
          break;
        }
        case 'vns': {
          if (this.props.platform === 'epilepsy') {
            const vnsTreatments = convertVnsToTimeline(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
            vnsTreatments &&
              convertedData.addonData?.treatments?.addons &&
              convertedData.addonData.treatments.addons.push(...vnsTreatments);
          }
          break;
        }
        case 'ventilationAid': {
          const ventilationAids = convertVentilationUseToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          ventilationAids &&
            convertedData?.addonData?.aids?.addons &&
            convertedData.addonData.aids.addons.push(...ventilationAids);
          break;
        }
        case 'mobilityAid': {
          const mobilityAids = convertMobilityAidToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          mobilityAids &&
            convertedData?.addonData?.aids?.addons &&
            convertedData.addonData.aids.addons.push(...mobilityAids);
          break;
        }
        case 'motorfunction': {
          const motorfunctions = convertMotorFunctionToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          motorfunctions &&
            convertedData?.addonData?.motorFunctions?.addons &&
            convertedData.addonData.motorFunctions.addons.push(...motorfunctions);
          break;
        }
        case 'nutrition': {
          const nutritions = convertNutritionToTimeline(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          nutritions &&
            convertedData?.addonData?.aids?.addons &&
            convertedData.addonData.aids.addons.push(...nutritions);
          break;
        }
        case 'comorbidity': {
          const comorbidities = convertComorbidityToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          comorbidities &&
            convertedData?.addonData?.comorbiditiesAndHospitalizations?.addons &&
            convertedData.addonData.comorbiditiesAndHospitalizations.addons.push(...comorbidities);
          break;
        }
        case 'hospitalization': {
          if (this.props.platform === 'mgravis') {
            const hosps = convertHospitalizationToTimelineMgravis(
              documents,
              this.state.fm,
              this.dateFromPartialUpdateTimeFrame,
            );
            hosps &&
              convertedData?.addonData?.hospitalizations?.addons &&
              convertedData.addonData.hospitalizations.addons.push(...hosps);
          } else {
            const hospitalizations = convertHospitalizationToTimeline(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            hospitalizations &&
              convertedData?.addonData?.comorbiditiesAndHospitalizations?.addons &&
              convertedData.addonData.comorbiditiesAndHospitalizations.addons.push(...hospitalizations);
          }
          break;
        }
        case 'procedure': {
          const procedures = convertProcedureToTimeline(documents, this.state.fm, this.dateFromPartialUpdateTimeFrame);
          procedures &&
            convertedData?.addonData?.procedures?.addons &&
            convertedData.addonData.procedures.addons.push(...procedures);

          const stemCellDocs = (documents as IProcedure[]).find(
            (p) => p._type === 'procedure' && p.code?.code === '1269349006' && isPartialDate(p.date),
          );
          const stemCells = convertStemcellsToTimeline(
            stemCellDocs ? [stemCellDocs] : [],
            this.state.fm,
            this.dateFromPartialUpdateTimeFrame,
          );
          stemCells &&
            convertedData?.addonData?.stemCellTherapy?.addons &&
            convertedData.addonData.stemCellTherapy.addons.push(...stemCells);
          break;
        }
        case 'diagnosticExamination': {
          const cnpExaminations = convertCnpExamsToTimeline(
            documents as Array<IDiagnosticExamination>,
            this.state.fm,
            this.dateFromPartialUpdateTimeFrame,
          );
          cnpExaminations &&
            convertedData?.addonData?.cnpExaminations?.addons &&
            convertedData.addonData.cnpExaminations.addons.push(...cnpExaminations);

          const diagnosticTests = convertDiagnosticTestsToTimeline(
            documents as Array<IDiagnosticExamination>,
            this.state.fm,
            this.dateFromPartialUpdateTimeFrame,
          );
          diagnosticTests &&
            convertedData?.addonData?.diagnosticTests?.addons &&
            convertedData.addonData.diagnosticTests.addons.push(...diagnosticTests);
          break;
        }
        case 'geneTest': {
          if (this.props.platform === 'parkinson') {
            const geneTests = convertGeneTestToTimeline(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
            geneTests &&
              convertedData?.addonData?.studies?.addons &&
              convertedData.addonData.studies.addons.push(...geneTests);
          }
          break;
        }
        case 'dexa':
        case 'cmap':
        case 'echocardiography':
        case 'muscleImaging':
        case 'scoliosis':
        case 'pelvis':
        case 'scintigraphy':
        case 'tt':
        case 'thymusCt':
        case 'thymusMri': {
          const imagings = convertImagingToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            this.props.platform,
          );
          if (this.props.platform === 'mgravis') {
            imagings &&
              convertedData?.addonData?.imaging?.addons &&
              convertedData?.addonData?.imaging?.addons.push(...imagings);
          } else {
            imagings &&
              convertedData?.addonData?.studies?.addons &&
              convertedData?.addonData?.studies?.addons.push(...imagings);
          }
          break;
        }
        case 'fvc':
        case 'nocturnalPolygraphy':
        case 'enmg':
        case 'vep':
        case 'sep':
        case 'baep':
        case 'octscan':
        case 'ecg':
        case 'bloodPressure':
        case 'heartRate': {
          if (['sma', 'dmd'].includes(this.props.platform || '')) {
            const clinicalStudies = convertClinicalStudyToTimeline(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
              this.props.platform,
            );
            clinicalStudies &&
              convertedData?.addonData?.studies?.addons &&
              convertedData.addonData.studies.addons.push(...clinicalStudies);
          }
          break;
        }
        case 'updrs_v': {
          if (this.props.platform === 'parkinson') {
            const updrs5s = convertUpdrsVToTimeline(documents, this.state.fm, this.dateFromPartialUpdateTimeFrame);
            updrs5s &&
              convertedData.addonData?.hoehnYahr?.addons &&
              convertedData.addonData?.hoehnYahr?.addons.push(...updrs5s);
          }
          break;
        }
        case 'msSymptoms':
        case 'relapse': {
          // MS symptoms are catched here as well
          if (this.props.platform === 'ms' && convertedData?.addonData?.relapse?.addons?.length === 0) {
            const relapseAndEarlySymptoms = convertRelapseAndEarlySymptomToTimeline(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            relapseAndEarlySymptoms &&
              convertedData?.addonData?.relapse?.addons &&
              convertedData.addonData.relapse.addons.push(...relapseAndEarlySymptoms);
          }
          break;
        }
        case 'therapy': {
          const therapy = convertTherapyToTimeline(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          therapy &&
            convertedData.addonData?.therapies?.addons &&
            convertedData.addonData.therapies.addons.push(...therapy);
          break;
        }
        case 'contraception': {
          const contraception = convertContraceptionToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          contraception &&
            convertedData.addonData.contraceptionAndPregnancies?.addons &&
            convertedData.addonData.contraceptionAndPregnancies.addons.push(...contraception);
          break;
        }
        case 'pregnancy': {
          const pregnancy = convertPregnancyToTimelineEpilepsy(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          pregnancy &&
            convertedData.addonData.contraceptionAndPregnancies?.addons &&
            convertedData.addonData.contraceptionAndPregnancies.addons.push(...pregnancy);
          break;
        }
        case 'otherSymptoms': {
          const otherSymptom = convertOtherSymptomToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          otherSymptom &&
            convertedData.addonData.otherSymptoms?.addons &&
            convertedData.addonData.otherSymptoms.addons.push(...otherSymptom);
          break;
        }
        case 'diet': {
          const dietTherapy = convertDietTherapyToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          if (this.props.platform === 'epilepsy') {
            dietTherapy &&
              convertedData.addonData.treatments?.addons &&
              convertedData.addonData.treatments.addons.push(...dietTherapy);
          }
          break;
        }
        case 'hifu': {
          const hifu = convertHifuToTimeline(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          hifu && convertedData.addonData?.hifu?.addons && convertedData.addonData?.hifu?.addons.push(...hifu);
          break;
        }
        case 'thalamotomy': {
          const thalamotomy = convertThalamotomyToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          thalamotomy &&
            convertedData.addonData?.thalamotomy?.addons &&
            convertedData.addonData?.thalamotomy?.addons.push(...thalamotomy);
          break;
        }
        case 'rtms': {
          const rtms = convertTreatmentsToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            documents.filter((d) => d._type === 'doctorsOrders'),
          );
          rtms &&
            convertedData.addonData?.treatments?.addons &&
            convertedData.addonData.treatments.addons.push(...rtms);
          const treatmentSessionsPerWeek = convertTreatmentSessionsPerWeekToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          treatmentSessionsPerWeek &&
            convertedData.graphData?.common?.treatmentSessionsPerWeek?.data &&
            convertedData.graphData.common.treatmentSessionsPerWeek.data.push(...treatmentSessionsPerWeek);
          const treatmentVisitsPerWeek = convertTreatmentVisitsPerWeekToGraph(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            ninmtTreatmentPeriodDocs,
          );
          treatmentVisitsPerWeek &&
            convertedData.graphData?.common?.treatmentVisitsPerWeek?.data &&
            convertedData.graphData.common.treatmentVisitsPerWeek.data.push(...treatmentVisitsPerWeek);

          const effectIntensity = convertEffectIntensityToGraph(
            documents.concat(allDocumentsMerged.filter((d) => d._type === 'tdcs')),
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            ninmtTreatmentPeriodDocs,
          );
          if (effectIntensity && convertedData.graphData?.common?.effectIntensity?.data) {
            convertedData.graphData.common.effectIntensity.data = [...effectIntensity]; // By design this overwrites array
          }

          const effectHarm = convertEffectHarmToGraph(
            documents.concat(allDocumentsMerged.filter((d) => d._type === 'tdcs')),
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            ninmtTreatmentPeriodDocs,
          );
          if (effectHarm && convertedData.graphData?.common?.effectHarm?.data) {
            convertedData.graphData.common.effectHarm.data = [...effectHarm]; // By design this overwrites array
          }
          break;
        }
        case 'tdcs': {
          const tdcs = convertTreatmentsToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            documents.filter((d) => d._type === 'doctorsOrders'),
          );
          tdcs &&
            convertedData.addonData?.treatments?.addons &&
            convertedData.addonData.treatments.addons.push(...tdcs);

          const effectIntensity = convertEffectIntensityToGraph(
            documents.concat(allDocumentsMerged.filter((d) => d._type === 'rtms')),
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            ninmtTreatmentPeriodDocs,
          );
          if (effectIntensity && convertedData.graphData?.common?.effectIntensity?.data) {
            convertedData.graphData.common.effectIntensity.data = [...effectIntensity]; // By design this overwrites array
          }

          const effectHarm = convertEffectHarmToGraph(
            documents.concat(allDocumentsMerged.filter((d) => d._type === 'rtms')),
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
            ninmtTreatmentPeriodDocs,
          );
          if (effectHarm && convertedData.graphData?.common?.effectHarm?.data) {
            convertedData.graphData.common.effectHarm.data = [...effectHarm]; // By design this overwrites array
          }
          break;
        }
        case 'inflammatoryDiseaseActivityStart':
        case 'inflammatoryDiseaseActivityMonitoring': {
          const inflammatoryDiseaseActivity = convertInflammatoryDiseaseActivityToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          inflammatoryDiseaseActivity &&
            convertedData.addonData?.diseaseActivity?.addons &&
            convertedData.addonData.diseaseActivity.addons.push(...inflammatoryDiseaseActivity);
          break;
        }
        case 'mgc': {
          const mgc = convertMGCToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          mgc && convertedData.graphData?.common.mgc.data && convertedData.graphData?.common.mgc.data.push(...mgc);
          break;
        }
        case 'mgfa': {
          const mgfa = convertMGFAToTimeline(documents, this.state.fm, this.dateFromPartialUpdateTimeFrame);
          mgfa && convertedData.addonData?.mgfa?.addons && convertedData.addonData?.mgfa?.addons.push(...mgfa);
          break;
        }
        case 'mgravisSymptoms': {
          const mgravisSymptoms = convertSymptomsToTimeline(
            documents,
            this.dateFromPartialUpdateTimeFrame,
            this.state.fm,
          );
          mgravisSymptoms &&
            convertedData.addonData?.earlySymptoms.addons &&
            convertedData.addonData?.earlySymptoms.addons.push(...mgravisSymptoms);
          break;
        }
        case 'background': {
          if (this.props.platform === 'ms' || this.props.platform === 'mgravis') {
            const pregnancies = convertPregnancyToTimeline(
              documents,
              this.dateFromPartialUpdateTimeFrame,
              this.state.fm,
            );
            pregnancies &&
              convertedData.addonData?.pregnancies.addons &&
              convertedData.addonData?.pregnancies.addons.push(...pregnancies);
          }
          break;
        }
        case 'fbimod': {
          const fbiMods = convertFbiModToGraph(documents, this.dateFromPartialUpdateTimeFrame, this.state.fm);
          fbiMods &&
            convertedData.graphData?.common?.fbiMod?.data &&
            convertedData.graphData.common.fbiMod.data.push(...fbiMods);
          break;
        }
      }
    };

    // Go through documents (types) that are relevant to the currently selected platform
    platformFilteredDocTypes.forEach(documentHandleLoop);

    // Handle appointments here
    const appointments = this.props.appointments || [];
    const handleAppointments = convertAppointmentToTimeline(
      appointments,
      this.dateFromPartialUpdateTimeFrame,
      this.state.fm,
    );
    handleAppointments && convertedData.addonData?.appointments?.addons?.push(...handleAppointments);

    // Handle myapp documents & events here
    const myappDocs: Array<{ [key: string]: any } & IControlProps> = this.props.myapp.sortedAndMergedDocuments ?? [];

    if (this.props.platform === 'ms' && myappDocs.length > 0) {
      const predss = convertPredssToGraph(
        myappDocs.filter((d) => d._type === 'status'),
        this.dateFromPartialUpdateTimeFrame,
      );
      const status = convertStatusToGraph(
        myappDocs.filter((d) => d._type === 'status'),
        this.state.fm,
        this.dateFromPartialUpdateTimeFrame,
      );
      const eq5d = convertEq5dToGraph(
        myappDocs.filter((d) => d._type === 'eq5d'),
        this.dateFromPartialUpdateTimeFrame,
      );
      const msis = convertMsisToGraph(
        myappDocs.filter((d) => d._type === 'msis'),
        this.dateFromPartialUpdateTimeFrame,
      );
      const fss = convertFssToGraph(
        myappDocs.filter((d) => d._type === 'fatigue'),
        this.dateFromPartialUpdateTimeFrame,
      );
      const msnq = convertMsnqToGraph(
        myappDocs.filter((d) => d._type === 'msnq'),
        this.dateFromPartialUpdateTimeFrame,
      );
      const fsmc = convertFsmcToGraph(
        myappDocs.filter((d) => d._type === 'fsmc'),
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      predss &&
        convertedData.graphData['selfReporting']?.predss?.data &&
        convertedData.graphData['selfReporting']?.predss?.data.push(...predss);
      status &&
        convertedData.graphData['selfReporting']?.status?.data &&
        convertedData.graphData['selfReporting']?.status?.data?.push(...status);
      eq5d &&
        convertedData.graphData['selfReporting']?.eq5d?.data &&
        convertedData.graphData['selfReporting']?.eq5d?.data?.push(...eq5d);
      msis &&
        convertedData.graphData['selfReporting']?.msis29?.data &&
        convertedData.graphData['selfReporting']?.msis29?.data?.push(...msis);
      fss &&
        convertedData.graphData['selfReporting']?.fss?.data &&
        convertedData.graphData['selfReporting']?.fss?.data?.push(...fss);
      msnq &&
        convertedData.graphData['selfReporting']?.msnq?.data &&
        convertedData.graphData['selfReporting']?.msnq?.data?.push(...msnq);
      fsmc &&
        convertedData.graphData['selfReporting']?.fsmc?.data &&
        convertedData.graphData['selfReporting']?.fsmc?.data?.push(...fsmc);
    }
    if (this.props.platform === 'ms' && myappDocs.length > 0) {
      const patientSelfReportEvents = convertPatientSelfReportEventsToTimeline(
        myappDocs,
        this.state.fm,
        this.dateFromPartialUpdateTimeFrame,
      );
      patientSelfReportEvents &&
        convertedData.addonData?.patientSelfReport?.addons &&
        convertedData.addonData?.patientSelfReport?.addons.push(...patientSelfReportEvents);
    }
    if (this.props.platform === 'ninmt' && myappDocs.length > 0) {
      const bdiAndBai = convertBdiAndBaiToGraph(
        myappDocs.filter((d) => d._type === 'bdi' || d._type === 'bai'),
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        this.latestNinmtTreatmentDecisionDate,
      );
      if (bdiAndBai && convertedData.graphData['selfReporting']?.bdiAndBai?.data) {
        convertedData.graphData['selfReporting'].bdiAndBai.data.push(...bdiAndBai);
      }
      const ninmtPreInquiries = myappDocs.filter((d) => d._type === 'ninmtPreInquiry') as (ININMTPreInquiry &
        IControlProps)[];
      const treatmentMonitoringInquiries = myappDocs.filter(
        (d) => d._type === 'treatmentMonitoringInquiry',
      ) as (ITreatmentMonitoringInquiry & IControlProps)[];
      const symptomsAndLocations = allDocumentsMerged.filter(
        (d) => d._type === 'symptomsAndLocations',
      ) as ISymptomsAndLocations[];

      const symptomsIntensity = convertSymptomsIntensityOrHarmToGraph(
        treatmentMonitoringInquiries,
        ninmtPreInquiries,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        'intensity',
        symptomsAndLocations,
      );

      if (symptomsIntensity && convertedData.graphData['selfReporting']?.symptomsIntensity?.data) {
        convertedData.graphData['selfReporting'].symptomsIntensity.data.push(...symptomsIntensity);
      }

      const symptomsHarm = convertSymptomsIntensityOrHarmToGraph(
        treatmentMonitoringInquiries,
        ninmtPreInquiries,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        'harm',
        symptomsAndLocations,
      );

      if (symptomsHarm && convertedData.graphData['selfReporting']?.symptomsHarm?.data) {
        convertedData.graphData['selfReporting'].symptomsHarm.data.push(...symptomsHarm);
      }

      const gic = convertGicToGraph(
        treatmentMonitoringInquiries,
        this.dateFromPartialUpdateTimeFrame,
        ninmtTreatmentPeriodDocs,
        isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
      );
      if (gic && convertedData.graphData['selfReporting']?.gic?.data) {
        convertedData.graphData['selfReporting'].gic.data.push(...gic);
      }
      const sleep = convertSleepToGraph(
        treatmentMonitoringInquiries,
        ninmtPreInquiries,
        this.dateFromPartialUpdateTimeFrame,
        ninmtTreatmentPeriodDocs,
        this.state.fm,
      );
      if (sleep && convertedData.graphData.selfReporting?.sleep?.data) {
        convertedData.graphData.selfReporting.sleep.data.push(...sleep);
      }
      const episodes = convertEpisodicSymptomsToGraph(
        treatmentMonitoringInquiries,
        ninmtPreInquiries,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        ninmtTreatmentPeriodDocs,
        isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
      );
      if (episodes && convertedData.graphData.selfReporting?.episodicSymptoms?.data) {
        convertedData.graphData.selfReporting.episodicSymptoms.data.push(...episodes);
      }
      const tdcsTreatmentsPerWeek = convertTdcsMyTreatmentsToGraph(
        myappDocs.filter((d) => d._type === 'tdcsReport'),
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        ninmtTreatmentPeriodDocs,
      );
      if (tdcsTreatmentsPerWeek && convertedData.graphData.selfReporting?.tdcsTreatmentsPerWeek?.data) {
        convertedData.graphData.selfReporting.tdcsTreatmentsPerWeek.data.push(...tdcsTreatmentsPerWeek);
      }
      const patientReportedMedications = convertPatientReportedMedicationsToTimeline(
        myappDocs.filter(
          (d) =>
            ['ninmtPreInquiry', 'treatmentMonitoringInquiry'].includes(d._type) &&
            // If medication is not at least an empty array in the document, medication has not been supported when creating the document.
            // Only documents with medication support are considered and included in graph.
            Array.isArray(d.medication),
        ),
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (patientReportedMedications && convertedData.addonData?.patientReportedMedications?.addons) {
        convertedData.addonData.patientReportedMedications.addons.push(...patientReportedMedications);
      }
      const eq5d = convertEq5dToGraph(
        myappDocs.filter((d) => d._type === 'eq5d'),
        this.dateFromPartialUpdateTimeFrame,
      );
      if (eq5d && convertedData.graphData.selfReporting?.eq5d?.data) {
        convertedData.graphData.selfReporting.eq5d.data.push(...eq5d);
      }
    }
    if (this.props.platform === 'sleepApnea' && myappDocs.length > 0) {
      const des = convertDesToGraph(
        myappDocs.filter((d) => d._type === 'des'),
        this.dateFromPartialUpdateTimeFrame,
        isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
      );
      if (des && convertedData.graphData.selfReporting?.des?.data) {
        convertedData.graphData.selfReporting.des.data.push(...des);
      }
      const ess = convertEssToGraph(
        myappDocs.filter((d) => d._type === 'ess'),
        this.dateFromPartialUpdateTimeFrame,
      );
      if (ess && convertedData.graphData.selfReporting?.ess?.data) {
        convertedData.graphData.selfReporting.ess.data.push(...ess);
      }
      const eq5d = convertEq5dToGraph(
        myappDocs.filter((d) => d._type === 'eq5d'),
        this.dateFromPartialUpdateTimeFrame,
        '5L',
      );
      if (eq5d && convertedData.graphData.selfReporting?.eq5d?.data) {
        convertedData.graphData.selfReporting.eq5d.data.push(...eq5d);
      }
    }
    if (this.props.platform === 'epilepsy' && myappDocs.length > 0) {
      const patRepSeizures = myappDocs.filter((md) => md._type === 'patientReportedSeizure') as Array<
        IPatientReportedSeizure & IControlProps
      >;
      const acceptedSeizures = patRepSeizures.filter((s) => s.status === 'accepted');

      const seizureMedication = convertSeizureMedicationToTimeline(
        acceptedSeizures,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      seizureMedication &&
        convertedData?.addonData?.medications?.addons &&
        convertedData.addonData.medications.addons.unshift(...seizureMedication);

      const seizuresPerDay = convertSeizuresToGraph(
        acceptedSeizures,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        'daily',
      );
      if (seizuresPerDay && convertedData.graphData.common?.seizuresPerDay?.data) {
        if (convertedData.graphData.common.seizuresPerDay.data.length > 0) {
          const hcpSeizuresConvertedData = structuredClone(
            convertedData.graphData.common.seizuresPerDay.data[0],
          ) as IData;
          seizuresPerDay[0].dataPoints.forEach((dp) => {
            const hcpDataSetIndex = hcpSeizuresConvertedData.dataPoints.findIndex(
              (point) => point.date.getTime() === dp.date.getTime(),
            );
            if (hcpDataSetIndex > -1) {
              const combinedValue = Number(
                (Number(dp.value) + Number(hcpSeizuresConvertedData.dataPoints[hcpDataSetIndex].value)).toFixed(1),
              );
              hcpSeizuresConvertedData.dataPoints[hcpDataSetIndex].value = combinedValue;
              hcpSeizuresConvertedData.dataPoints[hcpDataSetIndex].title = combinedValue.toString();
            } else {
              hcpSeizuresConvertedData.dataPoints.push(dp);
            }
          });
          convertedData.graphData.common.seizuresPerDay.data = [hcpSeizuresConvertedData];
        } else {
          convertedData.graphData.common?.seizuresPerDay?.data.push(...seizuresPerDay);
        }
      }
      const seizuresPerMonth = convertSeizuresToGraph(
        acceptedSeizures,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        'total',
      );
      if (seizuresPerMonth && convertedData.graphData.common?.seizuresTotal?.data) {
        if (convertedData.graphData.common.seizuresTotal.data.length > 0) {
          const hcpSeizuresConvertedData = structuredClone(
            convertedData.graphData.common.seizuresTotal.data[0],
          ) as IData;
          seizuresPerMonth[0].dataPoints.forEach((dp) => {
            const hcpDataSetIndex = hcpSeizuresConvertedData.dataPoints.findIndex(
              (point) => point.date.getTime() === dp.date.getTime(),
            );
            if (hcpDataSetIndex > -1) {
              const combinedValue = Number(
                (Number(dp.value) + Number(hcpSeizuresConvertedData.dataPoints[hcpDataSetIndex].value)).toFixed(1),
              );
              hcpSeizuresConvertedData.dataPoints[hcpDataSetIndex].value = combinedValue;
              hcpSeizuresConvertedData.dataPoints[hcpDataSetIndex].title = combinedValue.toString();
            } else {
              hcpSeizuresConvertedData.dataPoints.push(dp);
            }
          });
          convertedData.graphData.common.seizuresTotal.data = [hcpSeizuresConvertedData];
        } else {
          convertedData.graphData.common?.seizuresTotal?.data.push(...seizuresPerMonth);
        }
      }
      const seizuresPerMonthByType = convertSeizuresToGraph(
        acceptedSeizures,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        'byType',
      );
      if (seizuresPerMonthByType && convertedData.graphData.common?.seizuresByType?.data) {
        if (convertedData.graphData.common.seizuresByType.data.length > 0) {
          const omit = (key: string, obj: Record<string, any>): Record<string, any> => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [key]: omitted, ...rest } = obj;
            return rest;
          };
          const hcpSeizuresConvertedData = convertedData.graphData.common.seizuresByType.data.map((d) => {
            // Cannot clone legendDescription since it might contain React element(s)
            const dClone = structuredClone(omit('legendDescription', d));
            if ('legendDescription' in d && d.legendDescription && typeof d.legendDescription === 'object') {
              dClone.legendDescription = d.legendDescription;
            }
            return dClone;
          }) as IData[];
          seizuresPerMonthByType.forEach((spmbt) => {
            const hcpDataIndex = hcpSeizuresConvertedData.findIndex((data) => data.id === spmbt.id);
            if (hcpDataIndex > -1) {
              spmbt.dataPoints.forEach((dp) => {
                const hcpDataPointIndex = hcpSeizuresConvertedData[hcpDataIndex].dataPoints.findIndex(
                  (point) => point.alternativeDateString === dp.alternativeDateString,
                );
                if (hcpDataPointIndex > -1) {
                  const combinedValue = Number(
                    (
                      Number(dp.value) +
                      Number(hcpSeizuresConvertedData[hcpDataIndex].dataPoints[hcpDataPointIndex].value)
                    ).toFixed(1),
                  );
                  hcpSeizuresConvertedData[hcpDataIndex].dataPoints[hcpDataPointIndex].value = combinedValue;
                  hcpSeizuresConvertedData[hcpDataIndex].dataPoints[hcpDataPointIndex].title = combinedValue.toString();
                  // hcpSeizuresConvertedData[hcpDataIndex].dataPoints[hcpDataPointIndex].date = new Date(
                  //   hcpSeizuresConvertedData[hcpDataIndex].dataPoints[hcpDataPointIndex].date,
                  // );
                } else {
                  hcpSeizuresConvertedData[hcpDataIndex].dataPoints.push(dp);
                }
              });
            } else {
              hcpSeizuresConvertedData.push(spmbt);
            }
          });
          convertedData.graphData.common.seizuresByType.data = hcpSeizuresConvertedData;
        } else {
          convertedData.graphData.common?.seizuresByType?.data.push(...seizuresPerMonthByType);
        }
      }
    }
    if ((this.props.platform === 'sma' || this.props.platform === 'dmd') && myappDocs.length > 0) {
      const pedsqlFatigue = convertPedsqlFatigueToGraph(
        myappDocs.filter((d) => d._type === 'pedsql'),
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
      );
      if (pedsqlFatigue && convertedData.graphData.selfReporting?.pedsqlFatigue?.data) {
        convertedData.graphData.selfReporting.pedsqlFatigue.data.push(...pedsqlFatigue);
      }

      const pedsqlNeuromuscular = convertPedsqlNeuromuscularToGraph(
        myappDocs.filter((d) => d._type === 'pedsql'),
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
      );
      if (pedsqlNeuromuscular && convertedData.graphData.selfReporting?.pedsqlNeuromuscular?.data) {
        convertedData.graphData.selfReporting.pedsqlNeuromuscular.data.push(...pedsqlNeuromuscular);
      }
    }
    if (this.props.platform === 'parkinson' && myappDocs.length > 0) {
      const eq5d = convertEq5dToGraph(
        myappDocs.filter((d) => d._type === 'eq5d'),
        this.dateFromPartialUpdateTimeFrame,
        '5L',
      );
      if (eq5d && convertedData.graphData.selfReporting?.eq5d?.data) {
        convertedData.graphData.selfReporting.eq5d.data.push(...eq5d);
      }
      const pdq8 = convertPdq8ToGraph(
        myappDocs.filter((d) => d._type === 'pdq8'),
        this.dateFromPartialUpdateTimeFrame,
      );
      if (pdq8 && convertedData.graphData?.selfReporting?.pdq8?.data) {
        convertedData.graphData.selfReporting.pdq8.data.push(...pdq8);
      }
    }
    if (this.props.platform === 'mgravis' && myappDocs.length > 0) {
      const eq5d = convertEq5dToGraph(
        myappDocs.filter((d) => d._type === 'eq5d'),
        this.dateFromPartialUpdateTimeFrame,
        '5L',
      );
      if (eq5d && convertedData.graphData.selfReporting?.eq5d?.data) {
        convertedData.graphData.selfReporting.eq5d.data.push(...eq5d);
      }
      const passQuestion = convertPassQuestionToTimeline(
        myappDocs.filter((d) => d._type === 'passQuestion'),
        this.dateFromPartialUpdateTimeFrame,
        isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
        this.state.fm,
      );
      passQuestion &&
        convertedData.addonData?.passQuestion.addons &&
        convertedData.addonData.passQuestion.addons.push(...passQuestion);

      const mgadl = convertMGADLToGraph(
        myappDocs.filter((d) => d._type === 'mgadl'),
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
        isLocale(this.props.uiLanguage) ? this.props.uiLanguage : 'fi',
      );
      mgadl &&
        convertedData.graphData?.selfReporting.mgadl?.data &&
        convertedData.graphData?.selfReporting.mgadl.data.push(...mgadl);
    }

    // Handle ResMed docs
    if (
      this.props.platform === 'sleepApnea' &&
      (resMedTreatmentResponses.length > 0 || resMedDevices.length > 0 || resMedSettings.length > 0)
    ) {
      const papUsage = convertPapToGraph(
        [],
        resMedTreatmentResponses,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (papUsage && convertedData.graphData?.clinicalMetersPap?.pap?.data) {
        convertedData.graphData.clinicalMetersPap.pap.data.push(...papUsage);
      }

      const ahi = convertAHIToGraph(
        [],
        [],
        [],
        resMedTreatmentResponses,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (ahi && convertedData.graphData?.clinicalMetersPap?.ahi?.data) {
        convertedData.graphData.clinicalMetersPap.ahi.data.push(...ahi);
      }

      const maskLeak95Perc = convert95thPercentileMaskLeakToGraph(
        [],
        resMedTreatmentResponses,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (maskLeak95Perc && convertedData.graphData?.clinicalMetersPap?.maskLeak95thPercentile?.data) {
        convertedData.graphData.clinicalMetersPap.maskLeak95thPercentile.data.push(...maskLeak95Perc);
      }

      const aiCentral = convertAiCentralToGraph(
        [],
        resMedTreatmentResponses,
        allDocumentsMerged,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (aiCentral && convertedData.graphData?.clinicalMetersPap?.aiCentral?.data) {
        convertedData.graphData.clinicalMetersPap.aiCentral.data.push(...aiCentral);
      }

      const pressure95Perc = convert95thPercentilePressureToGraph(
        [],
        allDocumentsMerged,
        resMedTreatmentResponses,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (pressure95Perc && convertedData.graphData?.clinicalMetersPap?.pressure95thPercentile?.data) {
        convertedData.graphData.clinicalMetersPap.pressure95thPercentile.data.push(...pressure95Perc);
      }

      const tidalVolume95Perc = convertTidalVolume95PercentileToGraph(
        resMedTreatmentResponses,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (tidalVolume95Perc && convertedData.graphData?.clinicalMetersPap?.tidalVolume95Percentile?.data) {
        convertedData.graphData.clinicalMetersPap.tidalVolume95Percentile.data.push(...tidalVolume95Perc);
      }

      const respiratoryRate95Perc = convertRespiratoryRate95PercentileToGraph(
        resMedTreatmentResponses,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (respiratoryRate95Perc && convertedData.graphData?.clinicalMetersPap?.respiratoryRate95Percentile?.data) {
        convertedData.graphData.clinicalMetersPap.respiratoryRate95Percentile.data.push(...respiratoryRate95Perc);
      }

      const spontT = convertSpontTToGraph(resMedTreatmentResponses, this.dateFromPartialUpdateTimeFrame);
      if (spontT && convertedData.graphData?.clinicalMetersPap?.spontT?.data) {
        convertedData.graphData.clinicalMetersPap.spontT.data.push(...spontT);
      }

      const spontC = convertSpontCToGraph(resMedTreatmentResponses, this.dateFromPartialUpdateTimeFrame);
      if (spontC && convertedData.graphData?.clinicalMetersPap?.spontC?.data) {
        convertedData.graphData.clinicalMetersPap.spontC.data.push(...spontC);
      }

      const targetVentilation = convertTargetVentilationToGraph(
        resMedSettings,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );
      if (targetVentilation && convertedData.graphData?.clinicalMetersPap?.targetVentilation?.data) {
        convertedData.graphData.clinicalMetersPap.targetVentilation.data.push(...targetVentilation);
      }

      const treatments = convertTreatmentToTimeline(
        [],
        resMedDevices,
        resMedSettings,
        this.dateFromPartialUpdateTimeFrame,
        this.state.fm,
      );

      treatments &&
        convertedData.addonData?.treatments?.addons &&
        convertedData.addonData.treatments.addons.push(...treatments);
    }

    this.setState({ graphData: convertedData.graphData, addonData: convertedData.addonData });
  };

  loadExitusData = (patientData?: IPatientUI): IAddon | undefined => {
    // Jos exitus niin pusketaan sen tiedot topdataan
    if (patientData && patientData.isDead) {
      const exitus = convertExitusToTimeline(
        patientData,
        this.dateFromPartialUpdateTimeFrame,
        getSSNAgeInWholeMonths(this.props.ssn, patientData.dateOfDeath || undefined),
        this.state.fm,
      );
      return exitus;
    }
    return undefined;
  };

  // Function for updating graph width
  handleResize = (): void => {
    const w =
      window.innerWidth < 1400
        ? 89.4
        : window.innerWidth < 1850
          ? 134.8
          : window.innerWidth < 1920
            ? 180.2
            : window.innerWidth < 2375
              ? 180.2
              : 225.6;
    if (Math.abs(w - this.state.width) > 10) {
      this.setState({
        width: w,
      });
    }
  };

  loadGraphSettings = (): void => {
    // Fetch graphsettings here
    const gSettings = {};
    this.setState({ graphSettings: gSettings });
  };

  componentDidUpdate = (prevProps: Props): void => {
    if (
      prevProps.platform !== this.props.platform ||
      !equals(this.props.documents, prevProps.documents) ||
      !equals(this.props.myapp, prevProps.myapp) ||
      !equals(this.props.readonlyDocuments, prevProps.readonlyDocuments) ||
      !equals(this.props.selectedMedicationOrg, prevProps.selectedMedicationOrg)
    ) {
      // Clear all redux stored settings. This might not trigger at all since mount is usually called first
      this.props.setGraphSelections({ timeframe: null, timeframeLength: null, selectedGraph: null });
      this.clearData();
      this.loadDataFromDocs();
    }

    if (!equals(this.props.uiLanguage, prevProps.uiLanguage)) {
      this.setState({ loading: true });
      this.clearData();
      setTimeout(() => {
        this.loadDataFromDocs();
        this.setState({ loading: false });
      }, 200);
    }

    // Set graph as loaded when we have graphGata and patientData available
    if (this.props.patientData && this.state.graphData && this.state.addonData && !this.state.loaded) {
      this.setState({ loaded: true });
    }
  };

  componentDidMount = (): void => {
    this.mounted = true;

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.clearData();
    this.loadDataFromDocs();
  };

  componentWillUnmount = (): void => {
    this.mounted = false;

    window.removeEventListener('resize', this.handleResize);
  };

  reloadGraph = () => {
    this.props.setGraphSelections({ timeframe: null, timeframeLength: null });
    const myappDataFetchCapa = assertCapabilities(
      [PlatformCapabilities.MYSERVICE_INTEGRATION],
      this.props.capabilityGroups ?? {},
    );

    this.clearData();
    this.props.documentRefresh(false, myappDataFetchCapa).then(() => this.loadDataFromDocs());
  };

  changeSelectedMedicationOrg = (medOrg: string): void => {
    this.props.setSelectedMedicationOrg(medOrg);
  };

  render(): JSX.Element {
    const { graphData, addonData, loaded, loading, width } = this.state;
    const {
      org,
      orgGroupArray,
      platform,
      patientData,
      selectedMedicationOrg,
      grants,
      intl: { formatMessage },
      graphSelections,
    } = this.props;

    const getDisplayName = (orgId = '') => (orgGroupArray || []).find((og) => og.orgId === orgId)?.displayName;

    const grantorOrgs = (grants || []).map((g) => g.grantorOrg);
    const filteredGrantorOrgs = grantorOrgs.filter((o) => o !== org);

    // Clear addon data so that every update wont add more options
    if (addonData && 'medications' in addonData) addonData.medications.addonOptions = [];
    if (addonData && 'medications' in addonData && filteredGrantorOrgs.length > 0) {
      addonData.medications.addonOptions = [
        {
          type: 'radio',
          title: formatMessage({ id: 'medication.showSharedMedications' }),
          options: [org || '', ...filteredGrantorOrgs],
          selectedOption: selectedMedicationOrg || org || '',
          onOptionClick: (org: string) => {
            org && this.changeSelectedMedicationOrg(org || '');
          },
          optionFormatter: (opt: string) => (getDisplayName(opt) || '').toUpperCase(),
        },
      ];
    }

    // Add exitus
    const exitusData = this.loadExitusData(patientData);
    if (exitusData) (addonData || {}).exitus = { id: 'exitus', type: 'single', name: 'EXITUS', addons: [exitusData] };

    const firstDate = this.timeframe?.[0] || new Date();
    let lastDate = this.timeframe?.[1] && this.timeframe[1]?.getTime() > Date.now() ? this.timeframe[1] : new Date();

    const timeframeDefaults: TGraphSettings = {
      defaultTimeframeLength: graphSelections?.timeframeLength
        ? graphSelections.timeframeLength
        : this.latestNinmtTreatmentDecisionDate
          ? 'custom'
          : 'all',
      defaultTimeframe: graphSelections?.timeframe
        ? graphSelections.timeframe
        : this.latestNinmtTreatmentDecisionDate
          ? [this.latestNinmtTreatmentDecisionDate, new Date()]
          : null,
    };

    // If the default timeframe last date has been changed by scaling the timeframe up, then set that as total timeframe lastDate
    lastDate =
      timeframeDefaults.defaultTimeframe?.[1] && timeframeDefaults.defaultTimeframe?.[1]?.getTime() > lastDate.getTime()
        ? timeframeDefaults.defaultTimeframe[1]
        : lastDate;

    return loaded === true && loading === false && this.mounted === true && grants ? (
      <Graph
        width={width}
        paper
        settings={{
          ...this.state.graphSettings,
          reduxStateSelectedGraph: this.props.graphSelections?.selectedGraph,
          ...timeframeDefaults,
        }}
        platform={platform}
        graphData={graphData || { common: {} }}
        graphDataGroups={this.state.graphDataGroups}
        graphMenuSettings={this.state.graphMenuSettings}
        addonData={addonData || {}}
        totalTimeframe={[firstDate, lastDate]}
        reloadGraph={() => {
          this.reloadGraph();
        }}
        setGraphSelections={this.props.setGraphSelections}
        metaLocalizations={{
          selectedTimeframe: this.state.fm('graph.selectedTimeframe'),
          hideTimeframeSelectors: this.state.fm('graph.hideTimeframeSelectors'),
          hideRuler: this.state.fm('graph.hideRuler'),
          zoomIn: this.state.fm('graph.zoomIn'),
          zoomOut: this.state.fm('graph.zoomOut'),
          default: this.state.fm('graph.default'),
          timeframeLengthOptions: {
            all: this.state.fm('graph.timeframeLengthOptions.all'),
            custom: this.state.fm('graph.timeframeLengthOptions.custom'),
            '5y': this.state.fm('graph.timeframeLengthOptions.5y'),
            '3y': this.state.fm('graph.timeframeLengthOptions.3y'),
            '2y': this.state.fm('graph.timeframeLengthOptions.2y'),
            '1y': this.state.fm('graph.timeframeLengthOptions.1y'),
            '9m': this.state.fm('graph.timeframeLengthOptions.9m'),
            '6m': this.state.fm('graph.timeframeLengthOptions.6m'),
            '3m': this.state.fm('graph.timeframeLengthOptions.3m'),
            '2m': this.state.fm('graph.timeframeLengthOptions.2m'),
            '1m': this.state.fm('graph.timeframeLengthOptions.1m'),
            '14d': this.state.fm('graph.timeframeLengthOptions.14d'),
            '7d': this.state.fm('graph.timeframeLengthOptions.7d'),
            '3d': this.state.fm('graph.timeframeLengthOptions.3d'),
          },
        }}
      />
    ) : (
      <CenteredLoadingIndicator descriptionText={this.state.fm('general.loadingGraph')} />
    );
  }
}

interface IMapStateFromProps {
  documents: INeuroDocument[];
  sortedAndMergedDocuments: Array<{ [key: string]: any } & IControlProps>;
  myapp: IMyAppStore;
  loading: boolean;
  platform: Platform | null;
  ssn?: string;
  appointments?: IAppointment[];
  patientData?: IPatientUI;
  org?: string;
  orgGroupArray?: Pick<IVaultOrganization, 'displayName' | 'orgId'>[];
  selectedMedicationOrg?: string;
  grants?: INeuroGrant[];
  uiLanguage?: string;
  graphSelections?: ISessionStore['graphSelections'];
  orgSettings?: ISettingsStore['orgSettings'];
  readonlyDocuments?: Array<IReadonlyDocument>;
}

interface IMapDispatchFromProps {
  setSelectedMedicationOrg: (medOrg: string) => void;
  documentRefresh: (medSync?: boolean, myappDataFetch?: boolean) => Promise<any>;
  setGraphSelections: (selections: ISessionStore['graphSelections']) => void;
}

const mapStateToProps = (state: IState): IMapStateFromProps => {
  return {
    documents: state.documents.documents ?? [],
    sortedAndMergedDocuments: state.documents.sortedAndMergedDocuments ?? [],
    myapp: state.myapp,
    loading: state.documents.isLoading || false,
    platform: state.session.platforms?.selected || null,
    ssn: state.session.data?.patientssn,
    appointments: state.appointments.appointments,
    patientData: state.patient.data,
    org: state.session.data?.orgid,
    orgGroupArray: state.session.orgGroupArray,
    selectedMedicationOrg: state.session.selectedMedicationOrg,
    grants: state.patient.grants,
    uiLanguage: state.settings.userSettings.uiLanguage,
    graphSelections: state.session.graphSelections,
    orgSettings: state.settings.orgSettings,
    readonlyDocuments: state?.readonly?.documents ?? [],
  };
};

const mapDispatchToProps = (dispatch: TDispatch): IMapDispatchFromProps => ({
  setSelectedMedicationOrg: (medOrg: string) => dispatch(actions.setSelectedMedicationOrg(medOrg)),
  documentRefresh: (medSync = false, myappDataFetch = false) => {
    const promises = [
      docActions.fetchDocumentData(false, medSync)(dispatch),
      ...(myappDataFetch ? [myActions.loadMyAppData(dispatch)] : []),
    ];
    return Promise.all(promises);
  },
  setGraphSelections: (selections: ISessionStore['graphSelections']) =>
    dispatch(actions.setGraphSelections(selections)),
});

interface IOwnState {
  graphData: IDiseaseHistoryData['graphData'] | null;
  graphDataGroups?: IDiseaseHistoryData['graphDataGroups'] | null;
  graphMenuSettings?: IDiseaseHistoryData['graphMenuSettings'] | null;
  addonData: IDiseaseHistoryData['addonData'] | null;
  /** When data is still loading */
  loaded: boolean;
  ssn?: string;
  width: number;
  graphSettings?: Partial<IDashboardGraphProps['settings']>;
  medicationSettings?: IOrganizationNeuroSettings['medicationSettings'];
  /** When we want to manually set the graph as loading */
  loading: boolean;
  fm: (id: string, values?: { [key: string]: string }) => string;
}

interface IOwnProps extends ICapabilityContextProps {
  width?: number;
  height?: number;
  margin?: { top?: number; bottom?: number; left?: number; right?: number };
  intl: IntlShape;
}

export default withCapabilities(injectIntl(connect(mapStateToProps, mapDispatchToProps)(DashboardGraph)));
