import { styled } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import * as React from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'Components/ActionButton';
import { Container, Item } from 'Components/Grid';
import colors from '../../../config/theme/colors';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import { exists } from 'neuro-utils';
import ToolTip from 'Components/ToolTip';

const StyledValue = styled('div')({
  fontWeight: 600,
  marginBottom: '0.6rem',
});

const NewButton = styled('button')(({ theme }) => ({
  padding: 0,
  margin: 0,
  border: 'none',
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: '1.6rem',
  fontFamily: 'inherit',
}));

const ClearIconStyled = styled(ClearIcon, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled?: boolean }) => ({
  color: disabled ? colors.lightGray : colors.secondaryText,
  cursor: disabled ? undefined : 'pointer',
  '&:hover': {
    color: disabled ? undefined : 'red',
  },
}));

const SingleValueList = ({
  component,
  sortMethod,
  allowDuplicates = false,
  disableSaveButton,
  disabledTooltip,
  disableDeletion,
  ...inputProps
}: ISingleValueList) => {
  const [newItemData, setNewItemData] = React.useState<TFieldValue | 'noNewValueSet'>('noNewValueSet');
  const [deleteConfirmationIndex, setDeleteConfirmationIndex] = React.useState<number | null>(null);

  const fieldName = inputProps.name;
  const values = inputProps.value;
  const onChange = inputProps.onChange;

  const onAddItem = () => {
    let newValues: any[];
    if (!newItemData) return;
    if (Array.isArray(values)) {
      newValues = [...values, newItemData];
      sortMethod && newValues.sort(sortMethod);
      onChange && onChange({ [fieldName]: newValues });
    } else {
      newValues = [newItemData];
      onChange && onChange({ [fieldName]: newValues });
    }
    setNewItemData('noNewValueSet');
  };

  const onDeleteItem = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    onChange && onChange({ [fieldName]: newValues });
  };

  const onChangeNewItem = (newValues: TOnChangeValues) => {
    const newValue = newValues[fieldName];
    setNewItemData(newValue);
  };

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return (
    <>
      {Array.isArray(values) &&
        values.map((val, i) => (
          <Container key={i} style={{ alignItems: 'center', paddingTop: i > 0 ? '0.5rem' : undefined }}>
            <Item xs={true}>
              <StyledValue>{val}</StyledValue>
            </Item>
            {inputProps?.editing && (
              <Item xs={'auto'}>
                <ToolTip
                  description={fm('general.documentLocked')}
                  content={
                    <ClearIconStyled
                      onClick={disableDeletion?.(val) ? () => null : () => setDeleteConfirmationIndex(i)}
                      disabled={disableDeletion?.(val)}
                    />
                  }
                  hover={disableDeletion?.(val)}
                />
              </Item>
            )}
          </Container>
        ))}
      {!inputProps.editing && Array.isArray(values) && values.length === 0 && (
        <div style={{ fontWeight: 600 }}>{'-'}</div>
      )}

      {newItemData === 'noNewValueSet' && inputProps?.editing && (
        <div>
          <NewButton onClick={() => setNewItemData(undefined)}>{`+ ${fm('general.new')}`}</NewButton>
        </div>
      )}

      {newItemData !== 'noNewValueSet' && (
        <Container rowSpacing={'1rem'} columnSpacing={'1rem'}>
          <Item>
            {component({
              ...inputProps,
              onChange: onChangeNewItem,
              value: newItemData,
            })}
          </Item>
          <Item>
            <ActionButton
              text={'general.save'}
              onClick={onAddItem}
              width={8}
              height={2.5}
              fontSize={12}
              disabled={
                !exists(newItemData) ||
                (!allowDuplicates && values?.includes(newItemData)) ||
                disableSaveButton?.(newItemData)
              }
              disabledTooltip={
                exists(newItemData) && disableSaveButton?.(newItemData) && disabledTooltip ? (
                  <>{disabledTooltip}</>
                ) : exists(newItemData) ? (
                  <>{fm('general.valueExists')}</>
                ) : undefined
              }
            />
          </Item>
        </Container>
      )}

      <ConfirmationDialog
        text={fm('general.reallyWantToDelete')}
        open={!!deleteConfirmationIndex || deleteConfirmationIndex === 0}
        cancel={{ callback: () => setDeleteConfirmationIndex(null) }}
        confirm={{
          callback: () => {
            (deleteConfirmationIndex || deleteConfirmationIndex === 0) && onDeleteItem(deleteConfirmationIndex);
            setDeleteConfirmationIndex(null);
          },
        }}
      />
    </>
  );
};

export default SingleValueList;
