import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import * as React from 'react';
import MSAForm2008 from './MSA';
import MSAForm2022 from './MSA2022';

const MSAForm = ({ documents, formData, editing, view }: IFormContext<IMSA | IMSA2022>): JSX.Element | null => {
  const { onChange, document } = formData;
  const viewing = !!view?.viewing;

  const thisDocument = documents?.find((d) => d._id === editing || d._id === view?.viewing);

  const isNewDoc = !viewing && thisDocument._commitsLength === 1;

  const versionIs2008 = (d: typeof document): d is IFormData<IMSA>['document'] => {
    return !isNewDoc && (!d.version || d.version === '2008');
  };

  const version = versionIs2008(document) ? '2008' : '2022';

  React.useEffect(() => {
    if (!viewing && isNewDoc && !document.version && version === '2022') {
      onChange?.({ version: '2022' });
    }
  }, [version]);

  switch (version) {
    case '2008':
      return <MSAForm2008 />;
    case '2022':
      return <MSAForm2022 />;
  }
};

export default withFormContext(MSAForm);
