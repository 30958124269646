import DocumentHeader from 'Components/DocumentHeader';
import DocumentWrapper from 'Components/DocumentWrapper';
import { FormContextProvider, HistoryContextProvider } from 'Containers/FormContextHandler';
import FormEditingHandler from 'Containers/FormEditingHandler';
import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';
import { getHeaderId, TDocument } from '../utils';
import Form from './Form';
import GAFHistory from './HistoryRowData/GAF';
import MADRSHistory from './HistoryRowData/MADRS';

const InterviewSurveysHistory = (): JSX.Element => (
  <React.Fragment>
    <MADRSHistory />
    <GAFHistory />
  </React.Fragment>
);

const InterviewSurveys = ({ documents }: IOwnProps): JSX.Element => {
  const visitReason = useSelector((s: IState) => s.session.data?.visitreason);
  const visitReasonRetro = visitReason === 'retrospectiveDataTransfer';

  return (
    <FormEditingHandler name="interviewSurveys" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="interviewSurveys"
            headerId={getHeaderId(documents, editing, view?.viewing)}
            editing={editing}
            editButtons={<div />}
          />
          {editing && documents?.find((d: TDocument) => d._id === editing) ? (
            <FormContextProvider context={{ formData, documents, editing }}>
              <Form document={documents?.find((d: TDocument) => d._id === editing) as TDocument} />
            </FormContextProvider>
          ) : null}
          {view?.viewing && documents?.find((d: TDocument) => d._id === view?.viewing) ? (
            <FormContextProvider context={{ formData, documents, view }}>
              <Form document={documents?.find((d: TDocument) => d._id === view?.viewing) as TDocument} />
            </FormContextProvider>
          ) : null}
          {!editing && !view?.viewing ? (
            <HistoryContextProvider context={{ documents, startEdit, view, additionalData: { visitReasonRetro } }}>
              <InterviewSurveysHistory />
            </HistoryContextProvider>
          ) : null}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: TDocument[];
}

export default InterviewSurveys;
