import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import * as React from 'react';
import { MyServiceContext } from '..';
import TaskListForm, { TaskListFormProps } from './TaskList';
import { useAppSelector as useSelector } from 'Store/index';
import { Container, Item } from 'Components/Grid';
import { phone } from 'phone';
import { validate } from '@stellarq/email';
import TextField from 'Components/InputHandler/components/TextField';
import FormSection from 'Components/FormSection';
import ActionButton from 'Components/ActionButton';

const InviteForm = ({
  inviteContactInfo,
  setInviteContactInfo,
  taskListSettings,
  hasSomeTaskListData,
}: IInviteForm): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, editing, setEditingData, platform } = myServContext;

  const patientContactInfo = useSelector((s: { patient: IPatientStore }) => s.patient.contactInfo);

  React.useEffect(() => {
    if (editing) {
      setInviteContactInfo(patientContactInfo?.contacts ?? []);
    }
    return () => {
      setInviteContactInfo([]);
    };
  }, []);

  const onChangeInviteContactInfo = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if ((name === 'smsList' || name === 'emailList') && Array.isArray(value)) {
      const oldValues = inviteContactInfo
        ?.filter((ci) => (name === 'emailList' ? 'email' : 'sms') === ci.type)
        .map((ci) => ci.value);
      const newValue = value.find((v) => !oldValues?.includes(v));
      if (newValue) {
        setInviteContactInfo([
          ...(inviteContactInfo ?? []),
          {
            type: name === 'emailList' ? 'email' : 'sms',
            value: newValue,
            contactAllowed: true,
            rank: null,
            use: 'unknown',
          },
        ]);
      } else if (value.length < (oldValues?.length ?? 0)) {
        const removedValue = oldValues?.find((ov) => !value.includes(ov));
        if (removedValue) {
          setInviteContactInfo((inviteContactInfo ?? []).filter((ci) => ci.value !== removedValue));
        }
      }
    }
  };

  /** Allow the user to clear task list data in cases where they decide to send only the invite without the task list, even if both were initially entered */
  const clearTaskListData = (): void => {
    if (editing?.data) {
      const { data } = editing;
      const taskListFields = [
        'title',
        'deadline',
        'recurringTaskDeadline',
        'weeklySurveyInterval',
        'sendDate',
        'tasks',
        'description',
      ];
      const nulledTaskListData = taskListFields.reduce(
        (acc, field) => {
          if (field in data) acc[field] = null;
          return acc;
        },
        {} as Record<string, null>,
      );
      setEditingData?.({ ...data, ...nulledTaskListData });
    }
  };

  const sentInvites = useSelector((s: IState) => s.myapp.sentInvites);

  return (
    <React.Fragment>
      <FormRow title="myService.phoneNumber">
        <Container>
          <Item xs={5}>
            <InputHandler
              type="SingleValueList"
              name="smsList"
              editing={!!editing}
              value={inviteContactInfo?.filter((ci) => ci.type === 'sms').map((sms) => sms.value)}
              sortMethod={(a, b) => {
                const aRank = inviteContactInfo?.find((ci) => ci.value === a)?.rank;
                const bRank = inviteContactInfo?.find((ci) => ci.value === b)?.rank;
                return (
                  (aRank || aRank === 0 ? aRank : Number.MAX_SAFE_INTEGER) -
                  (bRank || bRank === 0 ? bRank : Number.MAX_SAFE_INTEGER)
                );
              }}
              formData={{
                onChange: onChangeInviteContactInfo,
                document: {
                  smsList: inviteContactInfo?.filter((ci) => ci.type === 'sms').map((sms) => sms.value),
                },
              }}
              disableSaveButton={(value) => {
                if (typeof value === 'string') return value.length === 0 || !phone(value, { country: 'FIN' }).isValid;
                return false;
              }}
              disabledTooltip={fm('profile.notValidSms')}
              disableDeletion={(value) => {
                return !!patientContactInfo?.contacts?.some((ci) => ci.value === value);
              }}
              component={(props) => {
                return <TextField {...props} type="TextField" placeholder={undefined} />;
              }}
            />
          </Item>
        </Container>
      </FormRow>
      <FormRow title="myService.emailAddress">
        <Container>
          <Item xs={5}>
            <InputHandler
              type="SingleValueList"
              name="emailList"
              editing={!!editing}
              value={inviteContactInfo?.filter((ci) => ci.type === 'email').map((email) => email.value)}
              sortMethod={(a, b) => {
                const aRank = inviteContactInfo?.find((ci) => ci.value === a)?.rank;
                const bRank = inviteContactInfo?.find((ci) => ci.value === b)?.rank;
                return (
                  (aRank || aRank === 0 ? aRank : Number.MAX_SAFE_INTEGER) -
                  (bRank || bRank === 0 ? bRank : Number.MAX_SAFE_INTEGER)
                );
              }}
              formData={{
                onChange: onChangeInviteContactInfo,
                document: {
                  emailList: inviteContactInfo?.filter((ci) => ci.type === 'email').map((email) => email.value),
                },
              }}
              disableSaveButton={(value) => {
                if (typeof value === 'string') return value.length === 0 || !validate(value);
                return false;
              }}
              disabledTooltip={fm('profile.notValidEmail')}
              disableDeletion={(value) => {
                return !!patientContactInfo?.contacts?.some((ci) => ci.value === value);
              }}
              component={(props) => {
                return <TextField {...props} type="TextField" placeholder={undefined} />;
              }}
            />
          </Item>
        </Container>
      </FormRow>
      <React.Fragment>
        {platform !== 'epilepsy' && (!sentInvites || sentInvites.length === 0) && (
          <FormSection
            header="myService.firstStimuli"
            headerControls={
              hasSomeTaskListData ? (
                <ActionButton text="general.clear" width={8} height={3} fontSize={14} onClick={clearTaskListData} />
              ) : undefined
            }
          >
            <TaskListForm taskListSettings={taskListSettings} />
          </FormSection>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

interface IInviteForm {
  inviteContactInfo: (INeuroContactInfo | INeuroUIContactInfo)[] | undefined;
  setInviteContactInfo: React.Dispatch<React.SetStateAction<(INeuroContactInfo | INeuroUIContactInfo)[] | undefined>>;
  taskListSettings?: TaskListFormProps;
  hasSomeTaskListData?: boolean;
}

export default InviteForm;
