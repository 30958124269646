// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/**
 * Omit fields from an object
 * @param {Array<string>} omittedFields - List of field names to be omitted
 * @param {Record<string, any>} object - An object
 * @returns {Record<string, any>} An object
 */
const omit = <T = { [key: string]: any }, K = string>(omittedFields: Array<K>, object: T): Omit<T, K> => {
  if (!object) return {};
  if (omittedFields.length < 1) return object;

  const omittedFieldsSet = new Set(omittedFields);
  return Object.fromEntries(Object.entries(object).filter((e) => !omittedFieldsSet.has(e[0])));
};

/**
 * Find a value inside an object by giving a path to it
 * TODO: Make it work with arrays as well inside or outside of objects
 * @param {Array<string|number>} path - List of field names, path to the wanted value
 * @param {Record<string, any>} object - An object
 * @returns {any} Value
 */
const path = (path: Array<string | number>, object: Record<string | number, any>): any => {
  let currentValue = undefined;
  let currentObject = object || {};
  path.every((fieldName) => {
    currentValue = currentObject?.[fieldName];
    if (typeof currentValue !== 'object') return false;
    else {
      currentObject = currentValue;
      return true;
    }
  });

  return currentValue;
};

/**
 * Return array with only unique values
 * TODO: Test that it works with objects as well
 * @param {Array<any>} array - An array
 * @returns {Array<any>} Value
 */
const uniq = (array: Array<any>): Array<any> => {
  return Array.from(new Set(array));
};

/**
 * Checks if the value is null or undefined
 * @param {any} value - A Value
 * @returns {boolean} Whether the value is null or undefined
 */
const isNil = (value: any): value is null | undefined => {
  return value === null || value === undefined;
};

/**
 * Take a certain amount of items from and array and return them as an array
 * @param {number} number - Number of values taken
 * @param {Array<any>} array - An array
 * @returns {boolean} New array
 */
const take = (number: number, array: Array<any>): Array<any> => {
  return array.slice(0, number);
};

export { omit, path, uniq, isNil, take };
