import * as React from 'react';
import EventStepper, { EventStepperGroup } from '../../../../../components/EventStepper';
import InputHandler from '../../../../../components/InputHandler';
import { formatPartialDate, partialDateToValue } from 'neuro-utils';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from 'Components/EventStepper/components';
import Treatments from './components/Treatments';
import { RTMSContext } from '../..';
import TabContent from 'Components/TabContent';
import Treatment from './components/Treatment';
import { equals } from 'ramda';
import {
  addingTreatmentSessionDisabledReason,
  findPrecedingDoctorsOrder,
  getSessionNumber,
  subjectOfTreatmentTitle,
} from 'Utility/ninmtUtil';
import { FormattedDoctorsOrderValue } from '../../functions';
import { RenderAlerts } from 'Components/Alerts';
import PatientsRating from './components/PatientsRating';
import PatientsRatings from './components/PatientsRatings';
import colors from '../../../../../config/theme/colors';

const Sessions = (): JSX.Element => {
  const rtmsContext = React.useContext(RTMSContext);
  const {
    formData,
    fm,
    doctorsOrders,
    allDoctorsOrders,
    ninmtTreatmentPeriods,
    contraIndications,
    symptomsAndLocations,
    setDoctorsOrdersReview,
    setAnchor,
  } = rtmsContext;

  // If all doctor's orders are "completed", no review is required
  const noReviewRequired = doctorsOrders.every((d) => d.completed);

  const buttonDisabledReason = addingTreatmentSessionDisabledReason('rtms', doctorsOrders, contraIndications, formData);

  // Add session createDate for more accurate sorting
  const creationMutator = (event: IRTMSSession): IRTMSSession => {
    return { ...event, createDate: Date.now() };
  };

  // Optionally require subjects of treatment, Device/RMT etc.
  const precedingDoctorsOrder = (index?: number, reqFields?: ('device' | 'rmt' | 'endRTMS')[], includeAll?: true) =>
    findPrecedingDoctorsOrder(
      'rtms',
      includeAll ? allDoctorsOrders : doctorsOrders,
      formData.document.sessions ?? [],
      index ?? 0,
      reqFields,
      includeAll ? ninmtTreatmentPeriods : undefined,
    );

  const precedingDocOrdBeforeAddingNewSes = React.useRef<IDoctorsOrder | undefined>(precedingDoctorsOrder(0, []));

  const [dateDefault, setDateDefault] = React.useState<'now' | PartialDate>('now');

  // Capping the session date
  React.useEffect(() => {
    // If preceding order document has ended rtms, cap the session date to that order date
    if (precedingDocOrdBeforeAddingNewSes.current && precedingDocOrdBeforeAddingNewSes.current.rtms?.endRTMS) {
      setDateDefault(precedingDocOrdBeforeAddingNewSes.current.date ?? 'now');
    } else {
      // If preceding order document has not ended rtms but future one has ended cap it to that
      const futureOrders = doctorsOrders.filter(
        (d) =>
          partialDateToValue(d.date) >
          partialDateToValue(formData.document.sessions?.[0]?.date ?? formData.document.date),
      );
      setDateDefault(futureOrders.find((o) => o.rtms?.endRTMS)?.date ?? 'now');
    }
  }, [precedingDocOrdBeforeAddingNewSes.current, formData.document.date]);

  const hasDeletedSessions =
    Array.isArray(formData.document.sessions) &&
    formData.document.sessions.length > 0 &&
    formData.document.sessions.some((s) => s.removeTS || s.removeInfo);

  // Hide/filter deleted sessions
  React.useEffect(() => {
    if (hasDeletedSessions) {
      formData.onChange?.({ sessions: formData.document.sessions?.filter((s) => !(s.removeTS || s.removeInfo)) });
    }
  }, [hasDeletedSessions]);

  return (
    <React.Fragment>
      <EventStepperGroup
        allClosed
        previousEventsCollapseLimit={5}
        previousEventsCollapseMessage={{ showMessage: 'rtms.showAllSessions', hideMessage: 'rtms.hideAllSessions' }}
      >
        <EventStepper
          name="sessions"
          formData={formData}
          mutators={{ creationMutator }}
          customCreateEventItem={
            noReviewRequired
              ? undefined
              : () => {
                  // If all doctor's orders are not completed, review is required
                  setAnchor('sessionsAndProtocols');
                  setDoctorsOrdersReview({ active: true, completed: false });
                }
          }
          deleteDialogMode="full"
          stepLabelText={(d: IRTMSSession): string => formatPartialDate(d?.date)}
          stepContent={(d: IRTMSSession): JSX.Element => (
            <React.Fragment>
              {['patientsRating', 'additionalInformation', 'device', 'rmt', 'subjectOfTreatment'].map((key, index) => {
                const currentIndex = formData.document.sessions?.findIndex((s) => equals(s, d));
                const value = d[key as keyof IRTMSSession];
                switch (key) {
                  case 'patientsRating': {
                    return (value as [])?.filter((s) => s).length > 0 ? (
                      <React.Fragment key={`${key}${index}`}>
                        <StepperHeaderValuePair
                          header={
                            <div style={{ whiteSpace: 'nowrap', fontWeight: 400 }}>
                              {fm('rtms.patientsRating.title')}
                            </div>
                          }
                          value={''}
                        />
                        <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                          <TabContent>
                            {
                              (value as [])?.map((s: IRTMSRating, i: number) => ({
                                key: `${s.id}${i}`,
                                id: `${s.id}Tab${i}`,
                                title: s.symptom
                                  ? fm(`rtms.patientsRating.opts.${s.symptom}`)
                                  : fm('rtms.patientsRating.symptomPlaceholder'),
                                content: (
                                  <PatientsRating document={s} onChange={(): string => ''} editingEvent={false} />
                                ),
                                count: i,
                              })) as []
                            }
                          </TabContent>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`${key}${index}`} />
                    );
                  }
                  case 'subjectOfTreatment': {
                    return (value as [])?.filter((s) => s).length > 0 ? (
                      <React.Fragment key={`${key}${index}`}>
                        <StepperHeaderValuePair header={fm('rtms.subjectOfTreatment.title')} value={''} />
                        <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                          <TabContent>
                            {(value as [])?.map((s: IRTMSTreatment, i: number) => ({
                              key: `${s.id}${i}`,
                              id: `${s.id}Tab${i}`,
                              title: subjectOfTreatmentTitle(s, 'rtms', fm),
                              ended: s.deleted,
                              content: (
                                <Treatment
                                  document={s}
                                  onChange={(): string => ''}
                                  session={d}
                                  sessionNumber={getSessionNumber(
                                    formData.document?.sessions ?? [],
                                    currentIndex ?? 0,
                                    s,
                                  )}
                                  precedingDoctorsOrder={precedingDoctorsOrder(currentIndex)}
                                  editingEvent={false}
                                />
                              ),
                              count: i,
                            }))}
                          </TabContent>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`${key}${index}`} />
                    );
                  }
                  default: {
                    return (
                      <StepperHeaderValuePair
                        key={`${key}${index}`}
                        header={fm(`rtms.${key === 'additionalInformation' ? 'additionalInformationSession' : key}`)}
                        value={
                          ['device', 'rmt'].includes(key) ? (
                            <FormattedDoctorsOrderValue
                              name={key as 'device' | 'rmt'}
                              precedingDoctorsOrder={precedingDoctorsOrder(currentIndex, [key as 'device' | 'rmt'])}
                            />
                          ) : (
                            value || '-'
                          )
                        }
                      />
                    );
                  }
                }
              })}
            </React.Fragment>
          )}
          addNewTextHeader="rtms.session"
          addNewTextButton="rtms.newSession"
          buttonDisabled={buttonDisabledReason ? true : false}
          buttonDisabledElement={
            <RenderAlerts
              alerts={[{ id: 'rtmsAlert', textID: `rtms.${buttonDisabledReason}Message`, severity: 'info' }]}
              elevation={0}
              width={80}
            />
          }
          previousEventsTextHeader="rtms.previousSessions"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.sessions?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.rtms?.endRTMS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={<div style={{ whiteSpace: 'nowrap' }}>{fm('rtms.patientsRating.title')}</div>}
                  input={<></>}
                />
                <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                  <PatientsRatings
                    editIndex={index}
                    formData={formData}
                    fm={fm}
                    symptomsAndLocations={symptomsAndLocations}
                  />
                </div>
                <StepperHeaderFormInputPair
                  header={fm('rtms.additionalInformationSession')}
                  input={
                    <InputHandler
                      type="TextArea"
                      editing={true}
                      name="additionalInformation"
                      formData={{
                        onChange,
                        document: {
                          additionalInformation: formData.document.sessions?.[index]?.additionalInformation || '',
                        },
                      }}
                      placeholder="rtms.additionalInformationSession"
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('rtms.device')}
                  input={
                    <FormattedDoctorsOrderValue
                      name="device"
                      precedingDoctorsOrder={precedingDoctorsOrder(index, ['device'], true)}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('rtms.rmt')}
                  input={
                    <FormattedDoctorsOrderValue
                      name="rmt"
                      precedingDoctorsOrder={precedingDoctorsOrder(index, ['rmt'], true)}
                    />
                  }
                />
                <StepperHeaderFormInputPair header={fm('rtms.subjectOfTreatment.title')} input={<></>} />
                <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                  <Treatments editIndex={index} formData={formData} fm={fm} doctorsOrders={doctorsOrders} />
                </div>
              </React.Fragment>
            );
          }}
        />
        <EventStepper
          name="unusedSessions"
          formData={formData}
          mutators={{ creationMutator }}
          stepLabelText={(d: IRTMSUnusedSession): string => formatPartialDate(d.date)}
          stepContent={(d: IRTMSUnusedSession): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('rtms.unusedSessionReason')}
                value={d.reason ? fm(`rtms.opts.unusedSessionReason.${d.reason}`) : '-'}
              />
              {d.reason && (
                <React.Fragment>
                  <StepperHeaderValuePair
                    header={fm('rtms.unusedSessionReasonDetails')}
                    value={d.reasonDetails ? fm(`rtms.opts.unusedSessionReasonDetails.${d.reasonDetails}`) : '-'}
                  />
                  {d.reasonDetails === 'other' && (
                    <StepperHeaderValuePair
                      header={fm('rtms.unusedSessionReasonDetailsOther')}
                      value={d.reasonDetailsOther && d.reasonDetailsOther.length > 0 ? d.reasonDetailsOther : '-'}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          addNewTextHeader="rtms.unusedSession"
          addNewTextButton="rtms.newUnusedSession"
          previousEventsTextHeader="rtms.previousUnusedSessions"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            const patientInducedReasonDetails = [
              'forgotten',
              'sick',
              'blockedByOtherReason',
              'confusionAboutSessionTime',
              'unknown',
              'other',
            ];
            const unitInducedReasonDetails = [
              'staffShortage',
              'deviceRelated',
              'cancelledDueToEmergencyPatient',
              'confusionInSessionBooking',
              'other',
            ];

            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.unusedSessions?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.rtms?.endRTMS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('rtms.unusedSessionReason')}
                  input={
                    <InputHandler
                      type="Radio"
                      editing={true}
                      name="reason"
                      formData={{
                        onChange,
                        document: {
                          reason: formData.document.unusedSessions?.[index]?.reason || '',
                          reasonDetails: formData.document.unusedSessions?.[index]?.reasonDetails,
                          reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther,
                        },
                      }}
                      options={['patientInduced', 'unitInduced']}
                      optionFormatter={(id: string | number) => fm(`rtms.opts.unusedSessionReason.${id}`)}
                      dependentFieldsRemovalWarning={true}
                      dependentFieldsList={
                        formData.document.unusedSessions?.[index]?.reasonDetails !== 'other'
                          ? () => ['reasonDetails', 'reasonDetailsOther']
                          : undefined
                      }
                    />
                  }
                />
                {formData.document.unusedSessions?.[index]?.reason && (
                  <React.Fragment>
                    <StepperHeaderFormInputPair
                      header={fm('rtms.unusedSessionReasonDetails')}
                      input={
                        <InputHandler
                          type="Radio"
                          editing={true}
                          name="reasonDetails"
                          formData={{
                            onChange,
                            document: {
                              reasonDetails: formData.document.unusedSessions?.[index]?.reasonDetails || '',
                              reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther,
                            },
                          }}
                          options={
                            formData.document.unusedSessions?.[index]?.reason === 'patientInduced'
                              ? patientInducedReasonDetails
                              : unitInducedReasonDetails
                          }
                          optionFormatter={(id: string | number) => fm(`rtms.opts.unusedSessionReasonDetails.${id}`)}
                          dependentFieldsRemovalWarning={true}
                          dependentFieldsList={() => ['reasonDetailsOther']}
                        />
                      }
                    />
                    {formData.document.unusedSessions?.[index]?.reasonDetails === 'other' && (
                      <StepperHeaderFormInputPair
                        header={fm('rtms.unusedSessionReasonDetailsOther')}
                        input={
                          <InputHandler
                            type="TextArea"
                            editing={true}
                            name="reasonDetailsOther"
                            formData={{
                              onChange,
                              document: {
                                reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther || '',
                              },
                            }}
                            placeholder="rtms.unusedSessionReasonDetailsOther"
                          />
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          }}
          theme={{ highlightColor: colors.highlight.light }}
        />
      </EventStepperGroup>
    </React.Fragment>
  );
};

export default Sessions;
