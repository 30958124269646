import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { isLocaleKey, mgadl, IMGADL } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import InfoPopover from 'Components/InfoPopover';
import QuestionStepperSimpler from 'Components/QuestionStepperSimpler';

export const mgadlSteps: Array<keyof IMGADL> = [
  'talking',
  'chewing',
  'swallowing',
  'breathing',
  'impairmentOfAbilityToBrushTeethOrCombHair',
  'impairmentOfAbilityToAriseFromAChair',
  'doubleVision',
  'eyelidDroop',
];

export const MGADL = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj, fm } = myServContext;
  const mgadlData = ((editing?.data || viewing?.data) ?? {}) as IMGADL & IControlProps;
  const mgadlLocalizations = mgadl.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const [useManualScore, setUseManualScore] = React.useState(
    mgadlData?.manualScore || mgadlData?.manualScore === 0 ? true : false,
  );

  const onChangeSurveyForm = (values: TOnChangeValues, dependencyTrigger?: boolean): void => {
    if (dependencyTrigger) {
      const clearedFieldsObj: IMGADL = {};
      const clearedFields = mgadlSteps.concat('manualScore');
      clearedFields.forEach((f) => {
        if (mgadlData?.[f] || mgadlData?.[f] === 0) {
          clearedFieldsObj[f] = undefined;
        }
      });
      setEditingData?.({
        ...mgadlData,
        ...clearedFieldsObj,
      });
    } else {
      const field = Object.keys(values)[0];
      const value = Object.values(values)[0];
      setEditingData?.({
        ...mgadlData,
        [field]: value,
      });
    }
  };

  return (
    <>
      <BlockWrapper
        title={viewing ? formatPartialDate(mgadlData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'mgadl', data: mgadlData });
                  },
                },
              ]
            : undefined
        }
        topPadding
      >
        {!viewing && (
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: mgadlData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <FormRow title="myService.mgravis.manualScore">
          <InputHandler
            type="CheckboxSingle"
            editing={!viewing}
            name="useManualScore"
            formData={{
              onChange: () => {
                onChangeSurveyForm({}, true);
                setUseManualScore(!useManualScore);
              },
              document: { useManualScore: useManualScore },
            }}
            option="true"
            showLabel={true}
            optionFormatter={(o) => fm(`general.${o}`)}
          />
        </FormRow>
        {useManualScore ? (
          <FormRow title={mgadlLocalizations[useLocale]['totalScore']} formatTitle={false}>
            <InputHandler
              name="manualScore"
              type="NumberField"
              formData={{
                document: { manualScore: mgadlData?.manualScore },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
              placeholder={fm('myService.score')}
              min={0}
              max={24}
            />
          </FormRow>
        ) : (
          <React.Fragment>
            <div style={{ padding: '1rem 0 2rem 0' }}>
              <QuestionStepperSimpler
                viewing={!editing}
                formData={{
                  document: mgadlData,
                  onChange: onChangeSurveyForm,
                }}
                steps={mgadlSteps}
                stepsLocales={(step) =>
                  `${mgadlSteps.indexOf(step as keyof IMGADL) + 1}. ` + mgadlLocalizations[useLocale][`labels.${step}`]
                }
                getStepContent={(step) => ({
                  opts: [0, 1, 2, 3],
                  optsLocale: (o: string | number) => mgadlLocalizations[useLocale][`opts.${step}.${o}`],
                })}
                simpleViewing
              />
            </div>
            <FormRow title={mgadlLocalizations[useLocale]['totalScore']}>
              <span style={{ fontWeight: 600 }}>
                {mgadl.calculators.isFilledMgadl(mgadlData) ? (
                  mgadl.calculators.mgadlTotalScore(mgadlData).yielded + ' / 24'
                ) : (
                  <InfoPopover
                    text={
                      <div>
                        <div>{fm('general.notFilled')}</div>
                        <div>{fm('myService.mgravis.notFilled')}</div>
                        {mgadlSteps
                          .filter((step) => !(mgadlData?.[step] || mgadlData?.[step] === 0))
                          .map((s) => (
                            <div key={s}>{`${mgadlSteps.indexOf(s) + 1}. ${
                              mgadlLocalizations[useLocale][`labels.${s}`]
                            }`}</div>
                          ))}
                      </div>
                    }
                    color="primary"
                  />
                )}
              </span>
            </FormRow>
          </React.Fragment>
        )}
      </BlockWrapper>
    </>
  );
};
