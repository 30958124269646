import { path } from 'ramda';
import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import { TDocument } from '../utils';
import IDAForm from './Form';
import StartHistory from './HistoryRowData/Start';
import MonitoringHistory from './HistoryRowData/Monitoring';
import { fm } from 'Components/FormatMessage';

const docType = (documents: Array<TDocument>, editing: string, view?: IView): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        documents.find((d) => d._id === editing),
      ) || undefined
    : documents && view?.viewing
      ? path(
          ['_type'],
          documents.find((d) => d._id === view.viewing),
        ) || undefined
      : undefined;

const InflammatoryDiseaseActivityHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): JSX.Element => {
  const StartDocs = documents?.filter((d) => d._type === 'inflammatoryDiseaseActivityStart');
  const MonitorDocs = documents?.filter((d) => d._type === 'inflammatoryDiseaseActivityMonitoring');

  return (
    <React.Fragment>
      <StartHistory documents={StartDocs as IInflammatoryDiseaseActivityStart[]} startEdit={startEdit} view={view} />
      <MonitoringHistory
        documents={MonitorDocs as IInflammatoryDiseaseActivityMonitoring[]}
        startEdit={startEdit}
        view={view}
      />
    </React.Fragment>
  );
};

const InflammatoryDiseaseActivity = ({ documents }: IOwnProps): JSX.Element => {
  const relapseDocs = documents?.filter((d) => d._type === 'relapse');
  const mriDocs = documents?.filter((d) => d._type === 'mri');

  return (
    <FormEditingHandler name="inflammatoryDiseaseActivity" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'inflammatoryDiseaseActivity'}
            header={
              <span>
                {docType(documents, editing, view) === 'inflammatoryDiseaseActivityStart'
                  ? fm('inflammatoryDiseaseActivity.inflammatoryDiseaseActivityStart')
                  : docType(documents, editing, view) === 'inflammatoryDiseaseActivityMonitoring'
                    ? fm('inflammatoryDiseaseActivity.inflammatoryDiseaseActivityMonitoring')
                    : fm('inflammatoryDiseaseActivity.title')}
                {docType(documents, editing, view) &&
                  (editing || view?.viewing) &&
                  (formData.document.date || formData.document.version) && (
                    <span>
                      &nbsp;
                      {'('}
                      {fm('inflammatoryDiseaseActivity.careGuidelines')}
                      &nbsp;
                      {formData.document.version === '2024' ? '2024' : '2020'}
                      {')'}
                    </span>
                  )}
              </span>
            }
            editing={editing}
          />
          {editing && documents.find((d) => d._id === editing) ? (
            <IDAForm
              formData={formData}
              document={documents.find((d) => d._id === editing) as TDocument}
              relapseDocs={relapseDocs}
              mriDocs={mriDocs}
            />
          ) : null}

          {view?.viewing && documents.find((d) => d._id === view.viewing) ? (
            <IDAForm
              formData={{ document: view.document, onChange: (): string => '' }}
              document={documents.find((d) => d._id === view.viewing) as TDocument}
              viewing={view.viewing}
            />
          ) : null}

          {!editing && !view?.viewing ? InflammatoryDiseaseActivityHistory(documents, startEdit, view) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default InflammatoryDiseaseActivity;
